#open-offcanvas-asistente{
  display: none;
  content: "\f4a6";
  font-family: 'Ionicons';
  position: absolute;
  left: 20px;
  top: 50%;
  @include centerY(-50%);
  color: white;
  font-size: 22px;
  cursor: pointer;
  @include breakpoint(sm){
    display: block;
  }
}

.asistente-wrapper{
  padding: 50px 0;
  .asistente-content{
    .asistente-header{
      height: 120px;
      position: relative;
      background-image: url(../images/asistente-header.jpg);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      padding: 0 10px;
      h1{
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        @include center(-50%, -50%);
        text-transform: uppercase;
        color: white;
        font-size: 24px;
        font-weight: 300;
        letter-spacing: 2px;
        width: 85%;
        text-align: center;
        @include breakpoint(xxs){
          width: 70%;
          font-size: 20px;
        }
        &:before{
          content: "";
          position: absolute;
          bottom: -15px;
          left: 50%;
          @include centerX(-50%);
          height: 1px;
          width: 200px;
          background: white;
          @include breakpoint(xxs){
            width: 45px;
          }
        }
      }
    }
    .asistente-header-ampliado{
      .title-ampliado{
        padding: 0;
        background: $blue;
        height: 270px;
        position: relative;
        @include breakpoint(sm){
          height: 215px;
        }
        @include breakpoint(xs){
          height: 120px;
        }
        h1{
          position: absolute;
          top: 50%;
          left: 50%;
          @include center(-50%, -50%);
          color: white;
          font-size: 20px;
          text-align: center;
          width: 90%;
          margin: 0;
          @include breakpoint(sm){
            width: 70%;
          }
          @include breakpoint(xs){
            margin-top: 20px;
          }
        }
        h2{
          position: absolute;
          top: 10px;
          left: 10px;
          color: white;
          font-size: 18px;
          margin: 0;
          &:before{
            content: "";
            position: absolute;
            left: 0;
            bottom: -10px;
            height: 1px;
            width: 200px;
            background: white;
          }
          @include breakpoint(xs){
            left: 50%;
            @include centerX(-50%);
            text-align: center;
            &:before{
              left: 50%;
              @include centerX(-50%);
            }
          }
        }
      }
      .textura{
        padding: 0;
        div{
          height: 270px;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          @include breakpoint(sm){
            height: 215px;
          }
          @include breakpoint(xs){
            height: 180px;
          }
        }
      }
    }
    .asistente-description{
      margin: 30px 0;
      p{
        margin: 0;
        font-size: 14px;
        color: #383838;
        text-align: justify;
      }
    }
    .asistente-cards{
      .col-card-xs-custom-12{
        @media (max-width: 449px){
          width: 100%;
        }
      }
      .asistente-card{
        border: 1px solid #d8dadb;
        border-radius: 2px;
        margin-bottom: 15px;
        .thumb{
          width: 100%;
          overflow: hidden;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          height: 175px;
        }
        .title{
          height: 75px;
          position: relative;
          h1{
            text-align: center;
            margin: 0;
            position: absolute;
            width: 90%;
            top: 50%;
            left: 50%;
            @include center(-50%, -50%);
            font-size: 15px;
            color: #434a54;
          }
        }
        .asist-btn{
          height: 50px;
          position: relative;
          border-top: 1px solid #d8dadb;
          transition: ease-out .18s;
          -webkit-transition: ease-out .18s;
          -moz-transition: ease-out .18s;
          -ms-transition: ease-out .18s;
          -o-transition: ease-out .18s;
          a{
            position: absolute;
            top: 50%;
            left: 50%;
            @include center(-50%, -50%);
            text-transform: uppercase;
            text-decoration: none;
            font-size: 15px;
            color: #383838;
            font-weight: 800;
            width: 100%;
            text-align: center;
            transition: ease-out .18s;
            -webkit-transition: ease-out .18s;
            -moz-transition: ease-out .18s;
            -ms-transition: ease-out .18s;
            -o-transition: ease-out .18s;
            &:hover,
            &:focus{
              text-decoration: none;
            }
          }
          &:hover{
            background: $blue;
            transition: ease-out .18s;
            -webkit-transition: ease-out .18s;
            -moz-transition: ease-out .18s;
            -ms-transition: ease-out .18s;
            -o-transition: ease-out .18s;
            a{
              color: white;
              transition: ease-out .18s;
              -webkit-transition: ease-out .18s;
              -moz-transition: ease-out .18s;
              -ms-transition: ease-out .18s;
              -o-transition: ease-out .18s;
            }
          }
        }
      }
      .asistente-pagination{
        text-align: center;
        margin-top: 30px;
        ul{
          display: inline-block;
          list-style: none;
          position: relative;
          padding: 0;
          &:before{
            content: "";
            position: absolute;
            height: 1px;
            width: 115%;
            background: #c8c8c8;
            left: 50%;
            top: 50%;
            @include center(-50%, -50%);
            z-index: -1;
          }
          li{
            display: inline-block;
            margin: 0 5px;
            background: #cfcfcf;
            padding: 4px 10px;
            border-radius: 100px;
            a{
              color: white;
              text-decoration: none;
              text-align: center;
            }
          }
          .active{
            background: #414141;
          }
        }
      }
    }
    .asistente-buttons-set{
      margin: 30px 0;
      text-align: right;
      @include breakpoint(xs){
        text-align: left;
      }
      .button{
        color: $blue;
        background-color: #fff;
        border: 1px solid #d8dadb;
        padding: 8px 15px;
        display: inline-block;
        letter-spacing: 0.5px;
        font-weight: 500;
        i{
          color: #d8dadb;
        }
      }
    }
    .asistente-ampliado-content{
      h1{
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 800;
        color: #383838;
        letter-spacing: 2px;
        border-bottom: 1px solid #cdcdcd;
        padding-bottom: 10px;
        margin-bottom: 20px;
      }
      p{
        font-size: 14px;
        color: #383838;
        text-align: justify;
      }
      ul{
        margin: 0;
        padding-left: 20px;
        li{
          font-size: 14px;
          color: #383838;
          text-align: justify;
        }
      }
    }
  }
  .asistente-sidebar-offcanvas-open{
    @include breakpoint(sm){
      left: 0!important;
      transition: ease-out .18s;
      -webkit-transition: ease-out .18s;
      -moz-transition: ease-out .18s;
      -ms-transition: ease-out .18s;
      -o-transition: ease-out .18s;
    }
    .theiaStickySidebar{
      @include breakpoint(sm){
        transform: none!important;
        top: inherit!important;
        left: inherit!important;
        width: auto!important;
        position: static!important;
      }
    }
  }
  .asistente-sidebar-offcanvas{
    @include breakpoint(sm){
      position: fixed!important;
      top: 0;
      left: -350px;
      padding: 0;
      width: 350px;
      height: 100%;
      background: white;
      z-index: 9999;
      border-right: 1px solid #efefef;
      transition: ease-out .18s;
      -webkit-transition: ease-out .18s;
      -moz-transition: ease-out .18s;
      -ms-transition: ease-out .18s;
      -o-transition: ease-out .18s;
    }
    @include breakpoint(xxs){
      width: 300px;
    }
    #close-offcanvas-asistente{
      position: absolute;
      right: 10px;
      top: 0px;
      font-size: 25px;
      cursor: pointer;
      display: none;
      @include breakpoint(sm){
        display: block;
      }
    }
    .asistente-sidebar{
      @include breakpoint(sm){
        padding: 50px 5px 0;
      }
      .panel-group{
        .panel{
          border-radius: 0;
          margin: 0;
          a{
            text-decoration: none;
            .panel-heading{
              position: relative;
              border-bottom: 1px solid #d8dadb;
              .panel-title{
                font-size: 16px;
                color: #7b7b7b;
                text-transform: uppercase;
                padding: 10px 0
              }
              &:before{
                content: "\f3d8";
                font-family: 'Ionicons';
                position: absolute;
                right: 20px;
                top: 50%;
                @include centerY(-50%);
                font-size: 20px;
              }
            }
          }
          .collapsed{
            .panel-heading{
              border-bottom: 0;
              &:before{
                content: "\f3d0";
              }
            }
          }
          .panel-collapse{
            .panel-body{
              height: 350px;
              overflow-y: scroll;
              padding: 15px 0;
              &::-webkit-scrollbar{
                width: 10px;
              }
              &::-webkit-scrollbar-button{
                display: none;
              }
              &::-webkit-scrollbar-track{
                background: #efefef;
                border-radius: 5px;
              }
              &::-webkit-scrollbar-thumb{
                background: #d8dadb;
                border-radius: 5px;
              }
              &::-webkit-resizer{
                display: none;
              }
              ul{
                list-style: none;
                padding: 0;
                margin: 0;
                li{
                  text-transform: capitalize;
                  padding: 5px 15px;
                  a{
                    color: #7b7b7b;
                    font-size: 14px;
                  }
                }
                .active{
                  border-left: 1px solid $blue;
                }
              }
            }
          }
        }
        .panel:last-child{
          border-top: 0;
        }
      }
    }
  }
}
