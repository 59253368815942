.flex-panel{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}


.wrap-color{
  background: #fafafa;
  padding: 70px 0;

  .section-color-content{
    background: white;
    border: 1px solid #efefef;
    .row{
      .sidebar-color-mobile-trigged{
        @include breakpoint(sm){
          position: fixed!important;
          top: 0;
          left: -60%;
          width: 60%;
          height: 100%;
          overflow-y: auto;
          background: white;
          z-index: 1062;
          padding: 0;
          overflow-y: auto!important;
          transition: ease-out .38s;
          -webkit-transition: ease-out .38s;
        }
        @include breakpoint(xs){
          width: 100%;
          left: -100%;
        }
        .theiaStickySidebar{
          @include breakpoint(sm){
            transform: none!important;
            position: static!important;
            width: auto!important;
            top: inherit!important;
            left: inherit!important;
          }
        }
      }
      .sidebar-color-mobile{
        left: 0;
        transition: ease-out .38s;
        -webkit-transition: ease-out .38s;
      }
      .sidebar-color{
        border-right: 1px solid #efefef;
        .title{
          position: relative;
          h1{
            @include breakpoint(xxs){
              font-size: 30px;
              padding: 20px 0;
            }
          }
          i{
            font-size: 35px;
            position: absolute;
            cursor: pointer;
            right: 20px;
            top: 50%;
            @include centerY(-50%);
            display: none;
            @include breakpoint(sm){
              display: block;
            }
          }
        }
        .color-applied-filters{
          h3{
            text-align: center;
            text-transform: uppercase;
            color: $blue;
            font-size: 18px;
            background: #f0f3f6;
            margin: 2px;
            padding: 15px 0;
          }
          .applied-filters{
            background: #f0f3f6;
            margin: 2px;
            padding: 15px 0 15px 15px;
            .filter{
              margin: 15px 0;
              a{
                border: none;
                background: white;
                float: left;
                box-shadow: none;
                padding: 5px 10px;
                &:focus{
                  outline: none;
                  box-shadow: none;
                }
              }
              h4{
                text-transform: uppercase;
                margin: 0;
                font-size: 12px;
                color: #747474;
                padding-left: 45px;
              }
              h5{
                text-transform: uppercase;
                margin: 0;
                font-size: 12px;
                font-weight: 600;
                color: #242424;
                padding-left: 45px;
              }
            }
          }
        }
        .color-search-bar{
          form{
            position: relative;
            height: 60px;
            .form-group{
              margin: 0;
              height: 100%;
              span{
                position: absolute;
                left: 20px;
                top: 50%;
                @include centerY(-50%);
                color: #b2b9bd;
              }
              input{
                width: 100%;
                height: 100%;
                position: absolute;
                top: 50%;
                @include centerY(-50%);
                padding-left: 60px;
                background-image: linear-gradient(#337ab7, #337ab7), linear-gradient(#efefef, #efefef);
                background-image: -webkit-linear-gradient(#337ab7, #337ab7), -webkit-linear-gradient(#efefef, #efefef);
              }
            }
          }
        }
        .panel-group{
          margin: 0;
          .panel{
            border: 0;
            a{
              .panel-heading{
                padding: 30px;
                border-bottom: 1px solid #efefef;
                @include breakpoint(xxs){
                  padding: 20px 30px;
                }
                .panel-title{
                  text-transform: uppercase;
                  font-size: 16px;
                  color: #7b7b7b;
                  position: relative;
                  &:after{
                    content: "\f3d8";
                    font-family: 'Ionicons';
                    font-size: 25px;
                    position: absolute;
                    right: 5px;
                    top: 50%;
                    @include centerY(-50%);
                  }
                }
              }
            }
            .collapsed{
              .panel-heading{
                border-bottom: 0;
                .panel-title{
                  &:after{
                    content: "\f3d0";
                  }
                }
              }
            }
            .panel-collapse{
              .panel-body{
                ul{
                  list-style: none;
                  padding: 0;
                  margin: 0;
                  li{
                    padding: 10px 0 10px 15px;
                    a{
                      text-transform: capitalize;
                      color: #7b7b7b;
                      font-size: 14px;
                      text-decoration: none;
                      // font-weight: 600;
                    }
                  }
                  .active{
                    a{
                      color: $blue;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .section-color-palette{
        @include respond-to(992){
          margin-left: -30px;
        }
        .title{
          padding: 15px 10px;
          height: 123px;
          overflow: hidden;
          h2{
            padding: 0;
            margin-bottom: 16px;
            position: relative;
            i{
              position: absolute;
              cursor: pointer;
              display: none;
              height: 100%;
              // @include respond-to(556){
                top: 50%;
                @include centerY(-50%);
                left: 20px;
              // }
              @include breakpoint(sm){
                display: block;
              }
              // @include breakpoint(xxs){
              //   top: -45px;
              //   left: 10px;
              // }
            }
          }
        }
        .color-palette{
          padding: 15px;
          // text-align: center;
          // a{
          nav{
            text-align: center;
          }
            .popover{
              border: none;
              border-radius: 0;
              .popover-content{
                padding: 0;
                min-width: 160px;
                .pop-square-one{
                  // background-color: #f4df54;
                  position: relative;
                  padding: 15px;
                  min-height: 120px;
                  .ion-ios-close-empty{
                    position: absolute;
                    right: 10px;
                    top: 0;
                    font-size: 25px;
                    cursor: pointer;
                  }
                  .ion-ios-plus-outline{
                    position: absolute;
                    right: 8px;
                    bottom: 5px;
                    font-size: 18px;
                    color: #424242;
                  }
                  h1{
                    color: #424242;
                    font-size: 16px;
                    font-weight: 400;
                    text-transform: uppercase;
                    margin: 0 0 10px 0;
                  }
                  h2{
                    color: #424242;
                    font-size: 13px;
                    text-transform: uppercase;
                    margin: 0;
                  }
                }
                .pop-squares-two-three{
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  .pop-square-two{
                    width: 50%;
                    // background-color: #e6cb17;
                    h3{
                      font-size: 10px;
                      color: #424242;
                      padding-left: 10px;
                    }
                  }
                  .pop-square-three{
                    width: 50%;
                    // background-color: #fffeeb;
                    h3{
                      font-size: 10px;
                      color: #424242;
                      padding-left: 10px;
                    }
                  }
                  .pop-square-four{
                    width: 100%;
                    // background-color: #fffeeb;
                    h3{
                      font-size: 10px;
                      color: #424242;
                      padding-left: 10px;
                    }
                  }
                }
              }
            }
            .h1-laboca{
              font-family: 'Roboto', sans-serif;
              font-weight: 300;
              color: #7b7b7b;
              text-transform: uppercase;
              margin: 15px 0;
              font-size: 18px;
              position: relative;
              text-align: center;
              background: white;
              z-index: 2;
              overflow: hidden;
              &:before{
                content: "";
                position: absolute;
                width: 500px;
                height: 1px;
                background: #ccc;
                left: -225px;
                top: 50%;
                @include centerY(-50%);
                z-index: 1;
              }
              &:after{
                content: "";
                position: absolute;
                width: 500px;
                height: 1px;
                background: #ccc;
                right: -225px;
                top: 50%;
                @include centerY(-50%);
                z-index: 1;
              }
            }
            .item{
              cursor: pointer;
              width: 9.3%;
              min-width: 60px;
              // background: red;
              margin: 5px;
              display: block;
              float: left;
              position: relative;
              border: 1px solid #efefef;
              @include breakpoint(md){
                width: 12.1%;
              }
              @include breakpoint(xs){
                width: 17.3%;
              }
              @include breakpoint(xxs){
                width: 28.1%;
              }
              // .item-expanded{
              //   background: rgba(56, 128, 96, 1);
              //   position: absolute;
              //   top: 0;
              //   left: 0;
              //   width: 0;
              //   height: 0;
              //   opacity: 0;
              //   z-index: -1;
              //   span{
              //     font-size: 25px;
              //   }
              // }
            }
          // }
        }
      }
    }
    .title{
      border-bottom: 1px solid #efefef;
      h1{
        color: $blue;
        font-size: 48px;
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        margin: 0;
        padding: 35px 0;
      }
      h2{
        color: $blue;
        font-weight: 300;
        text-align: center;
        font-size: 24px;
        text-transform: uppercase;
        margin: 0;
        padding: 48px 0;
        span{
          font-weight: 600;
        }
      }
    }
  }
}


.color-detail{
  padding: 20px 0;
  .container{
    .wrap-color-detail{
      border: 1px solid #efefef;
      padding: 10px;
      margin-top: 20px;

      .header-img{
        height: 270px;
        width: 100%;
        background: white;
        display: flex;
        border: 1px solid $blue;
        flex-direction: column;
        overflow: hidden;
        // padding: 25px;
        @include breakpoint(xs){
          min-height: 200px;
          height: auto;
        }
        .flex-row{
          justify-content: space-between;
        }
        .img-superficie-parent{
          padding: 0;
        }
        .col-md-8{
          display: flex;
          flex-direction: column;
        }
        .imagen-superficie{
          height: 270px;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
        }
        h1{
          text-transform: uppercase;
          font-weight: 300;
          font-size: 20px;
          font-family: 'Roboto', Arial;
          margin: 15px 0 0 0;
          position: relative;
          color: $blue;
          @include breakpoint(xxs){
            font-size: 20px;
          }
        }
        .separador{
          width: 50px;
          height: 1px;
          background: $blue;
          margin: 10px 0;
        }
        h2{
          text-transform: uppercase;
          font-weight: 300;
          font-size: 38px;
          color: $blue;
          font-family: 'Roboto', Arial;
          align-self: center;
          margin-top: 60px;
          @include breakpoint(xxs){
            font-size: 30px;
          }
        }
      }
      .header-1{
        height: 270px;
        width: 100%;
        // background: #f4df54;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 25px;
        @include breakpoint(xs){
          min-height: 200px;
          height: auto;
        }
        h1{
          text-transform: uppercase;
          font-weight: 400;
          font-size: 48px;
          color: #424242;
          font-family: 'Roboto', Arial;
          @include breakpoint(xxs){
            font-size: 40px;
          }
        }
        h2{
          text-transform: uppercase;
          font-weight: 300;
          font-size: 38px;
          color: #515050;
          font-family: 'Roboto', Arial;
          @include breakpoint(xxs){
            font-size: 30px;
          }
        }
      }
      .header-2{
        height: 270px;
        width: 100%;
        // background: #f4df54;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 25px;
        position: relative;
        @include breakpoint(xs){
          height: auto;
        }
        h1{
          text-transform: uppercase;
          font-weight: 400;
          font-size: 48px;
          color: #424242;
          font-family: 'Roboto', Arial;
          @include breakpoint(xxs){
            font-size: 40px;
          }
        }
        h2{
          text-transform: uppercase;
          font-weight: 300;
          font-size: 38px;
          color: #515050;
          font-family: 'Roboto', Arial;
          @include breakpoint(sm){
            margin-top: 0;
            margin-bottom: 20px;
          }
          @include breakpoint(xxs){
            font-size: 30px;
          }
        }
        .mezclas{
          width: 100%;
          height: 100%;
          @include respond-to(992){
            position: absolute;
            top: 0;
            left: 0;
          }
          @include breakpoint(sm){
            display: flex;
            align-items: flex-end;
          }
          @include breakpoint(xs){
            align-items: center;
            flex-direction: column;
          }
          .mezcla-item{
            width: 150px;
            height: 50%;
            padding: 15px;
            @include respond-to(992){
              position: absolute;
              right: 0;
            }
            @include breakpoint(sm){
              float: left;
              height: 75px;
              width: 100%;
              position: relative;
            }
            @include breakpoint(xs){
              width: 75%;
            }
            @include breakpoint(xxs){
              float: none;
              width: 100%;
            }
            a{
              i{
                position: absolute;
                right: 5px;
                top: 5px;
                font-size: 22px;
                color: black;
                @include breakpoint(xxs){
                  right: 10px;
                  top: 50%;
                  @include centerY(-50%);
                  font-size: 20px;
                }
              }
            }
            h5{
              text-transform: uppercase;
              color: black;
              font-size: 16px;
              line-height: 22px;
              @include breakpoint(xxs){
                font-size: 14px;
              }
            }
          }
          .mezcla-item-1{
            background: #e6cb17;
            @include respond-to(992){
              top: 0;
            }
          }
          .mezcla-item-2{
            background: #fffeeb;
            @include respond-to(992){
              top: 50%;
            }
          }
          .mezcla-item-3{
            background: #d4bf31;
            @include respond-to(992){
              top: 0;
              right: 150px;
            }
          }
          .mezcla-item-4{
            background: #c4ad15;
            @include respond-to(992){
              top: 50%;
              right: 150px;
            }
          }
        }
      }
      .panel-group{
        margin-top: 20px;
        .panel{
          border-radius: 0;
          margin-top: 20px;
          border-color: #efefef;
          a{
          .panel-heading{
            background: white;
            position: relative;
              .panel-title{
                text-transform: uppercase;
                font-weight: 100;
                font-size: 25px;
                color: #515050;
                position: relative;
                float: left;
                font-family: 'Roboto', sans-serif;
                letter-spacing: 3px;
                @include breakpoint(xs){
                  font-weight: 300;
                  font-size: 16px;
                  letter-spacing: 0px;
                  width: 85%;
                }
              }
              .panel-icon{
                position: relative;
                display: inline-block;
                text-align: center;
                font-weight: normal;
                font-size: 20px;
                cursor: pointer;
                float: right;
                padding: 0 9px;
                border: 1px solid #f1f1f1;
                &:before{
                  content: "\f462";
                  display: inline-block;
                  font-family: "Ionicons";
                  color: #515050;
                  speak: none;
                  font-style: normal;
                  font-weight: normal;
                  font-variant: normal;
                  text-transform: none;
                  text-rendering: auto;
                  line-height: 1;
                  -webkit-font-smoothing: antialiased;
                }
              }
            }
          }
          .collapsed{
            .panel-heading{
              .panel-icon{
                &:before{
                  content: "\f489";
                }
              }
            }
          }
          .panel-collapse{
            .panel-body{
              border-top: 0;
              min-height: 155px;
              .row.fx{
                width: 100%;
              }
              .tonos-item{
                width: 80px;
                height: 80px;
                // background: #f6e888;
                margin: 10px;
                padding: 5px 10px;
                cursor: pointer;
                border: 1px solid #d6d5c9;
                position: relative;
                transition: ease-out .18s;
                -webkit-transition: ease-out .18s;
                @include breakpoint(xs){
                  width: 105px;
                  height: 105px;
                }
                h4,h5{
                  color: #2b2b2b;
                  font-size: 14px;
                  text-transform: uppercase;
                  font-family: 'Roboto', sans-serif;
                  opacity: 0;
                  transition: ease-out .18s;
                  -webkit-transition: ease-out .18s;
                  transition-delay: 0s;
                  -webkit-transition-delay: 0s;
                }
                h4{
                  font-weight: 400;
                }
                h5{
                  font-weight: 100;
                }
                a{
                  i{
                    position: absolute;
                    bottom: 0px;
                    right: 5px;
                    font-size: 19px;
                    color: #2b2b2b;
                    opacity: 0;
                    transition: ease-out .18s;
                    -webkit-transition: ease-out .18s;
                    transition-delay: .18s;
                    -webkit-transition-delay: 0s;
                  }
                }
              }
              .tono-item-active{
                width: 105px;
                height: 105px;
                transition: ease-out .18s;
                -webkit-transition: ease-out .18s;
                cursor: default;
                h4,h5,a i{
                  opacity: 1;
                  transition: ease-out .18s;
                  -webkit-transition: ease-out .18s;
                  transition-delay: .18s;
                  -webkit-transition-delay: .18s;
                }
              }
              .similares-item{
                width: 105px;
                height: 105px;
                background: #e0c71a;
                margin: 20px 5px;
              }
              .detalle-header{
                background-position: center;
                background-size: cover;
                padding: 70px 30px;
                h1{
                  text-transform: uppercase;
                  color: white;
                  font-size: 48px;
                  font-weight: 700;
                  font-family: 'Roboto', sans-serif;
                  margin: 0;
                  @include breakpoint(xs){
                    font-size: 35px;
                  }
                }
                h2{
                  color: white;
                  text-transform: uppercase;
                  font-size: 24px;
                  font-family: 'Roboto', sans-serif;
                  font-weight: 500;
                  margin: 0;
                  @include breakpoint(xs){
                    font-size: 20px;
                  }
                  span{
                    font-weight: 100;
                  }
                }
              }
              #la-boca{
                background-image: url(../images/color/color-detalle-header-1.jpg);
              }
              #esmalte-header{
                background-image: url(../images/color/esmalte-header.jpg);
              }
              #lav-ext{
                background-image: url(../images/color/exteriores-1.jpg);
              }
              #lav-int{
                background-image: url(../images/color/interiores-3.jpg);
              }
              #tersinol{
                background-image: url(../images/color/tersinol-1.jpg);
              }
              .row.flex-row{
                padding: 30px 0 10px;
              }
              .texto-detalle{
                padding: 50px;
                @include breakpoint(xxs){
                  padding: 20px;
                }
                p{
                  color: #515151;
                  font-size: 14px;
                  font-family: 'Roboto', sans-serif;
                  font-weight: 300;
                  text-align: justify;
                  span{
                    font-weight: 500;
                  }
                }
              }
              .image-detalle{
                border-left: 1px solid #bfbfbf;
                border-right: 1px solid #bfbfbf;
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                padding: 50px;
                @include breakpoint(sm){
                  border-left: 0;
                  border-right: 0;
                  padding: 50px 30px;
                }
                .image-detalle-content{
                  display: inline;
                  .img-detail-panel{
                    display: inline-block;
                    overflow: hidden;
                    width: 85px;
                    height: 85px;
                  }
                }
              }
              .relacionados-detalle{
                padding: 30px;
                h3{
                  font-weight: 400;
                  color: #515151;
                  font-size: 14px;
                  font-family: 'Roboto', sans-serif;
                }
                .item-detalle{
                  width: 50px;
                  height: 50px;
                  // background: red;
                  display: inline-block;
                }
              }
            }
          }
        }
      }
    }
  }
}




.owl-main-color{
  .owl-stage-outer{
    overflow: inherit;
    .owl-stage{
      .color-family-item{
        width: 40px;
        height: 40px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border: 1px solid #efefef;
        margin: auto;
      }
      .active-item-family{
        -webkit-box-shadow: inset 0px 0px 0px 5px rgba(58,116,195,1);
        -moz-box-shadow: inset 0px 0px 0px 5px rgba(58,116,195,1);
        box-shadow: inset 0px 0px 0px 5px rgba(58,116,195,1);
      }
    }
  }
}



.change-color-offcanvas{
  background: white;
  border-left: 1px solid #efefef;
  position: fixed;
  width: 100%;
  max-width: 420px;
  height: 100%;
  right: -420px;
  z-index: 1062;
  top: 0;
  overflow-y: scroll;
  transition: ease-out .28s;
  -webkit-transition: ease-out .28s;
  @include breakpoint(xs){
    max-width: 320px;
    right: -320px;
  }
  #close-ofcanvass-change-color{
    font-size: 30px;
    cursor: pointer;
    margin-left: 10px;
  }
  .cambiar-flia-btn{
    text-align: center;
    button{
      background: transparent;
      border: 1px solid #efefef;
      color: #333;
      padding: 10px 20px;
    }
  }
  .owl-offcanvas-change-color{
    padding: 0 35px;
    .color-family-item{
      margin: auto;
    }
    .owl-controls{
      .owl-nav{
        .owl-prev{
          position: absolute;
          left: 15px;
          top: 50%;
          @include centerY(-50%);
        }
        .owl-next{
          position: absolute;
          right: 15px;
          top: 50%;
          @include centerY(-50%);
        }
      }
    }
  }
  .color-palette-intern{
    margin-top: 20px;
  }
  .color-palette{
    .item{
      cursor: pointer;
      width: 9.3%;
      min-width: 60px;
      border: 1px solid #efefef;
      margin: 5px;
      display: inline-block;
      position: relative;
      @include breakpoint(md){
        width: 12.1%;
      }
      @include breakpoint(xs){
        width: 17.3%;
      }
      @include breakpoint(xxs){
        width: 28.1%;
      }
    }
    .mostrar-mas{
      margin-top: 20px;
      text-transform: capitalize;
      position: relative;
      color: #333333;
      &:after{
        content: "\f3d0";
        font-family: 'Ionicons';
        margin-left: 10px;
      }
    }
  }
}
.change-color-offcanvas-open{
  right: 0;
  transition: ease-out .28s;
  -webkit-transition: ease-out .28s;
}

.color-pag{
  text-align: center;
}
#color-detail-product{
  padding: 70px 0;
}

.color-detalle-producto{
  border: 1px solid #efefef;
  padding: 0 15px;
  margin-top: 30px;
  .botones-bottom-detail{
    margin: 15px 0 25px;
  }
  h1{
    font-size: 25px;
    color: #515050;
    text-transform: uppercase;
    font-weight: 300;
    font-family: 'Roboto', sans-serif;
    margin: 30px 0;
    span{
      color: #b2b2b2;
    }
    @include breakpoint(xs){
      text-align: center;
      margin: 15px 0;
      font-size: 20px;
    }
  }
  .parent-ficha-tecnica{
    text-align: right;
    @include breakpoint(xs){
      text-align: center;
    }
    button{
      background: transparent;
      border: 1px solid #efefef;
      color: #333;
      padding: 10px 20px 10px 35px;
      margin-top: 22px;
      position: relative;
      @include breakpoint(xs){
        margin-top: 10px;
        margin-bottom: 25px;
      }
      &:before{
        content: "\f105";
        font-family: 'Ionicons';
        position: absolute;
        top: 50%;
        @include centerY(-50%);
        left: 12px;
        font-size: 20px;
        color: #b2b9bd;
      }
    }
  }
  .detalle-producto-interna{
    border: 1px solid #efefef;
    padding: 15px;
    .main-detalle-color-prod{
      text-align: center;
      img{
        max-width: 100%;
        margin: 20px auto;
      }
    }
    .color-palette-detail-border{
      border-left: 1px solid #efefef;
      border-right: 1px solid #efefef;
      .color-detail-square-1{
        background-color: #f4df54;
        padding: 15px;
        min-height: 130px;
        h1{
          color: #424242;
          font-size: 19px;
          text-transform: uppercase;
          font-weight: 500;
          font-family: 'Roboto', sans-serif;
          margin: 0 0 10px 0;
        }
        h2{
          color: #515050;
          font-size: 17px;
          text-transform: uppercase;
          font-weight: 300;
          letter-spacing: 2px;
          font-family: 'Roboto', sans-serif;
          margin: 0;
        }
      }
      .color-detail-square-2{
        display: flex;
        justify-content: flex-start;
        .color-detail-square-3{
          min-width: 50%;
          width: 100%;
          h3{
            font-size: 14px;
            color: #2b2b2b;
            text-transform: uppercase;
            padding-left: 10px;
            margin: 10px 0;
          }
        }
      }
      .boton-cambiar-color{
        text-align: center;
        button{
          background: transparent;
          color: #333333;
          font-size: 12px;
          border: 1px solid #e5e5e5;
          font-family: 'Roboto', sans-serif;
          font-weight: 400;
          box-shadow: none;
          margin: 20px 0;
        }
      }
    }
    .card-detail-producto-color{
      margin: 0 0 40px 0;
      img{
        float: left;
      }
      h4{
        color: #7b7b7b;
        font-size: 12px;
        position: relative;
        padding-left: 55px;
        &:before{
          content: "";
          position: absolute;
          width: 75%;
          height: 2px;
          background-color: $blue;
          bottom: -6px;
        }
      }
      h5{
        color: #080808;
        font-size: 12px;
        padding-left: 55px;
      }
    }
  }
}


.botones-bottom-detail{
  text-align: right;
  padding-top: 15px;
  @include breakpoint(xs){
    float: none!important;
    text-align: center;
  }
  button{
    color: #333333;
    font-size: 12px;
    background-color: transparent;
    border: 1px solid #efefef;
    padding: 10px 30px;
  }
}
