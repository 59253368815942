@mixin background-size($all) {
    -webkit-background-size: $all;
    -moz-background-size: $all;
    background-size: $all;
}

/* ------------------------------------------------------------- MIXINS ------------------------------------------------------------- */

// BREAKPOINTS

@mixin respond-to($breakpoint) {
  @media only screen and (min-width: $breakpoint + px) { @content; }
}

@mixin breakpoint($class) {
  @if $class == xxs {
    @media (max-width: 555px) { @content; }
  }

  @if $class == xs {
    @media (max-width: 767px) { @content; }
  }

  @else if $class == sm {
    @media (max-width: 991px) { @content; }
  }

  @else if $class == md {
    @media (max-width: 1199px) { @content; }
  }

  @else if $class == lg {
    @media (max-width: 1439px) { @content; }
  }

  @else if $class == xlg {
    @media (min-width: 1440px) { @content; }
  }
}

// CENTER BLOCK

@mixin center($centerY, $centerX){
  transform: translate($centerY, $centerX)!important;
  -webkit-transform: translate($centerY, $centerX)!important;
  -moz-transform: translate($centerY, $centerX)!important;
  -ms-transform: translate($centerY, $centerX)!important;
  -o-transform: translate($centerY, $centerX)!important;
}
@mixin centerY($centerY){
  transform: translateY($centerY)!important;
  -webkit-transform: translateY($centerY)!important;
  -moz-transform: translateY($centerY)!important;
  -ms-transform: translateY($centerY)!important;
  -o-transform: translateY($centerY)!important;
}
@mixin centerX($centerX){
  transform: translateX($centerX)!important;
  -webkit-transform: translateX($centerX)!important;
  -moz-transform: translateX($centerX)!important;
  -ms-transform: translateX($centerX)!important;
  -o-transform: translateX($centerX)!important;
}

// Background Retina

$retina: "only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx)";


@mixin retina-bg($file, $type) {
  background-image: url($file + '.' + $type);
  @media #{$retina} {
    background-image: url($file + '@2x.' + $type);
  }
}
