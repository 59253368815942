.compartir-producto-color{
  padding: 100px 0;
  .content{
    width: 512px;
    border: 1px solid #efefef;
    margin: auto;
    height: 212px;
    padding: 5px;
    .imagen-producto{
      width: 250px;
      background: lightblue;
      float: left;
      img{
        width: 100%;
      }
    }
    .color-producto{
      width: 250px;
      float: right;
      .main-color{
        width: 100%;
        height: 100px;
        background: #e6cb17;
        padding: 5px;
        h2{
          // color: #515050;
          font-size: 16px;
          text-transform: uppercase;
          letter-spacing: 1px;
          margin: 0 0 5px 0;
        }
        h1{
          // color: #515050;
          font-size: 18px;
          text-transform: uppercase;
          letter-spacing: 1px;
          margin: 0;
        }
      }
      .color-prod-mezcla{
        width: 125px;
        height: 50px;
        float: left;
        padding: 5px;
        h2{
          // color: #515050;
          font-size: 12px;
          text-transform: uppercase;
          letter-spacing: 1px;
          margin: 0 0 5px 0;
        }
        h1{
          // color: #515050;
          font-size: 13px;
          text-transform: uppercase;
          letter-spacing: 1px;
          margin: 0;
        }
      }
      .color-1{
        background: #fffeeb;
      }
      .color-2{
        background: #c7b32c;
      }
      .color-3{
        background: #908225;
      }
      .color-4{
        background: red;
      }
      .color-5{
        background: red;
        width: 100%!important;
      }
      .color-6{
        background: #908225;
        width: 100%!important;
      }
    }
  }
}


.color-header-compartir{
  padding: 100px 0;
  .content{
    width: 512px;
    border: 1px solid #efefef;
    margin: auto;
    height: 212px;
    padding: 5px;
    .main-color-header{
      width: 250px;
      height: 200px;
      float: left;
      background: red;
      padding: 5px;
      h2{
        // color: #515050;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin: 0 0 5px 0;
      }
      h1{
        // color: #515050;
        font-size: 13px;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin: 0;
      }
    }
    .mezclas{
      width: 250px;
      float: right;
      .mezcla-item{
        width: 125px;
        height: 100px;
        float: left;
        padding: 5px;
        h2{
          // color: #515050;
          font-size: 12px;
          text-transform: uppercase;
          letter-spacing: 1px;
          margin: 0 0 5px 0;
        }
        h1{
          // color: #515050;
          font-size: 13px;
          text-transform: uppercase;
          letter-spacing: 1px;
          margin: 0;
        }
      }
      .mezcla-item-row-1{
        width: 100%;
        display: block;
        .mezcla-item-1{
          background: lightblue;
        }
        .mezcla-item-2{
          background: lightgreen;
        }
        .mezcla-item-6{
          background: lightgreen;
          width: 100%!important;
        }
      }
      .mezcla-item-row-2{
        width: 100%;
        display: block;
        .mezcla-item-3{
          background: lightyellow;
        }
        .mezcla-item-4{
          background: green;
        }
        .mezcla-item-5{
          background: green;
          width: 100%!important;
        }
      }
    }
  }
}
