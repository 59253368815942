/* MEDIA QUERIES */
// variables declaradas en bootstrap-variables
// $screen-sm-min: 768px;
// $screen-xs-max: ($screen-sm-min - 1);
// $screen-md-min: 992px;
// $screen-sm-max: ($screen-md-min - 1);
// $screen-lg-min: 1200px;
// $screen-md-max: ($screen-lg-min - 1);
$screen-xss-min: 550px;



.lineas-prod .row{
	.box{
			&:nth-of-type(1){
				img{
					@include breakpoint(sm){
						left: 0;
					}
					@include breakpoint(xxs){
						left: -55px;
					}
				}
			}
	}
}

@media (min-width: $screen-lg-min) {
	section#historia article{
		// min-height: 100vh;
		min-height: calc(100vh - 102px);
	}
}

@media (max-width: $screen-md-max) {
	#carousel-home .carousel-caption {
		padding-top: 90px;
		left:7%;

		ul{
			font-size: 16px;
		}


	}
	.bird{
		display: none;
	}
	.balde{
		float: left;
	}
	#sec-products{
		.col-md-9{
			.filters{
				a.bt {
					padding: 0 20px !important;
				}
			}
		}
	}
	.lineas-prod .row{
		.box{
				&:nth-of-type(1){
					img{
						// left:-40px;
						left: -55px;
					}
				}
		}
	}

}

@media (max-width: $screen-sm-max) {
	#carousel-home .carousel-caption {
		left:0%;
		ul{
			font-size: 14px;
		}
	}
	header .top a {
		font-size: 10px;
	}
	.navbar-header {
		float: left;
	}
	.navbar-toggle {
		float: left;
		margin: 30px 0 0;

		.icon-bar {
			background-color: #333;
			width: 30px;
		}
	}
	.navbar-collapse{
		top:4px;
		padding:0;
	}
	.navbar-collapse {
		background: #FFF;
		position: relative;
		width: 100%;
		z-index: 3;
	}

	.container-fluid>.navbar-collapse, .container-fluid>.navbar-header, .container>.navbar-collapse, .container>.navbar-header {
		margin: 0;
	}

	.navbar-collapse.in{
		border-bottom: 3px solid #3a74c3;
		border-top: 0;
		border-left: 1px solid #ccc;
		border-right: 1px solid #ccc;
	}

	.navbar-nav{
		margin:0 -15px;
	}

	nav.navbar .navbar-nav > li{
		 border-bottom: 1px solid #DFE1E3!important;
		 overflow: hidden;
	 &.active{
		 border:0;
		 box-shadow: inset 25px 0px 0px -2px rgba(214,19,0,1);
		 background-color:#F8F9FA;
	 }
	 a{
		 padding: 15px 30px !important;
		 line-height: normal !important;

		 i{
			 float:right;
		 }

		 .fa-angle-right{
			 display: inline-block!important;
		 }

	 }
 }
	.carousel-caption {
		left: 10%;
	}

	section .col-md-8 .box {
		padding: 40px 0 0 20px;
	}

	footer .col-md-8 {
		overflow: hidden;
		// padding-top: 20px;
	}

	.buscador .col-md-6:first-child {
		border-bottom: 1px solid #ddd;
		border-right: none;
		padding-bottom: 30px;
		margin-bottom: 30px;
	}

	.buscador .col-md-6:last-child .line {
		margin-top: 20px;
	}

	.lineas-prod {
		@include background-size(cover);
	}

	section#sec-products aside {
		border-right: none;
		padding-bottom: 30px;
		padding-right: 15px;

		a.lineasHide {
			display: block;
		}

		.menus {
			display: none;
		}
	}
	section#sec-products .col-md-9 {
		padding-left: 15px;
	}
	section#sec-products .detalles .col-md-9 {
		margin: 0;
		padding: 30px;
	}
	section#sec-products .detalles .col-md-3 {
		margin-bottom: -1px;
		margin-right: 0;
		padding-right: 15px;
	}
	section#sec-products .detalles .nav-tabs > li {
		float: left;
		margin: 0;
		margin-right: 2px;

		a {
			margin: 0;
		}
	}
	section#sec-products .detalles .nav-tabs > li.active > a, section#sec-products .detalles .nav-tabs > li.active > a:focus, section#sec-products .detalles .nav-tabs > li.active > a:hover {
		border: 1px solid #e6e6e6;
		border-bottom-color: #FFF;
	}

	section#historia {
		nav {
			right: 3%;

			ul {
				width: 70px;

				li.active {
					height: 70px;
					width: 70px;

					a {
						font-size: 23px;
						line-height: 70px;
					}

					&:after {
						left: 49%;
						top: 69px;
					}
				}

				li:after {
					left: 45%;
				}

				li:first-child, li:last-child {
					margin-left: -10px;
				}
			}
		}

		article p, article h2, article span.bg {
			width: 70%;
		}
	}

}

@media (max-width: $screen-sm-max) {

	header{
		.top{
			.text-right{
				a{
					padding: 0 15px;

					i{
						display: none;
					}

					span{

					}
				}
			}
		}
		> .container{
			padding: 0;
		}
		.navbar-header{
			position: relative;
			width: 100%;

			.navbar-brand{
				position: absolute;
				left: 50%;
				margin-left:-80px;
				float:none;
			}

		}
		.navbar-toggle{
			margin:30px 10px 0;
		}
		.navbar-collapse{
			top:40px;
		}

	}

	#carousel-home{
		.carousel-caption {
		    padding-top: 100px;

		    img{
		    	width: 50%;
		    }

		    ul {
		    	padding: 0;
		    	position: absolute;
			    right: 0;
			    top: 120px;

			    li{
			    	margin-bottom: 15px;
			    }
		    }

		    a{
		    	position: absolute;
	    		top: 260px;
	    		right: 130px;
	    		padding: 10px 50px;
		    }
		}
	}


	.wrp-home{


		.col-md-8 {
			// position: relative;

			#carousel-example-generic{
				height: 100%;
				@include breakpoint(xs){
					height: 420px;
				}
			}

			.box {
				padding: 20px;

				a{
					padding:15px 35px;
				}

				h1{
					span{
						font-size:26px;
					}
				}

				p{
					margin-bottom: 20px;
				}
			}
		}
	}


	 .buscador {
		width: 300px;
	}

	.gris{
		.row{
			padding: 0 20px;
		}
	}

	.top-title{
		h1{
			font-size: 30px;
		}
		p{
			font-size: 16px;
		}
	}



	footer {
		ul{
			font-size: 10px;
			padding: 0;
		}
		.calidad{
			display: none;
		}
		.iqnet{
			display: none;
		}
		form{
			input{
				margin-bottom:20px;
				width: 100%;
			}
		}
	}

	section#sec-products .col-md-9 .filters .btn-group {
		margin: 0;
		width: 100%;
	}
	section#sec-products .col-md-9 .filters .btn, #sec-products .col-md-9 .filters .dropdown-menu {
		width: 100%;
	}
	section#sec-products .col-md-9 .filters > a {
		display: inline-block;
		margin-top: 20px;
	}
	section#historia {
		nav {
			display: none;
		}

		article {
			span.bg {
				font-size: 50px;
			}

			h2 {
				font-size: 28px;
			}

			p {
				font-size: 14px;
			}
		}
	}
	section#sec-products .detalles .nav-tabs > li > a{
		font-size:12px;
		padding: 20px 7px;
	}
	.contat-us-wrap .cobertura {
		left:0;
		top:0;
	}
	.contat-us-wrap #contactForm .page-title h1{
		font-size: 50px;
	}

}

@media (max-width: 550px) {


	#carousel-home{
		.carousel-caption{
			img{
				float: none;
				margin: 0 auto;
    			width: 60%;
			}

			ul{
				position:relative;
				top:0;
				padding-left: 25px;

				li{

					i{
						font-size: 25px;
						margin-right: 5px;
					}
				}
			}

			a{
				position:relative;
				top:0;
				right:0;
				margin:0 0 60px 60px;
			}
		}
	}

	section#historia article p, section#historia article h2, section#historia article span.bg{
		width: 90%;
	}

}




// MEDIA QUERIES FIX BUGS 15-12-2016

.wrp-home{
	.col-md-8 {
		@include breakpoint(xs){
			position: relative;
		}
	}
}
