#direccionespintureria::-moz-placeholder {
  /* Firefox 19+ */
  line-height: 40px;
}

select {
  // -webkit-appearance: menu-item;
  -webkit-appearance: none;
  // -webkit-appearance: menulist-button;
}

.col-xxs-12 {
  @media (max-width: 449px) {
    width: 100%;
  }
}

.visible-custom-xs {
  display: none;

  @media (max-width: 449px) {
    display: inline-block;
  }
}

.encontrar-pintureria {
  margin-right: 10px;
  padding-left: 42px;
  position: relative;
  text-transform: uppercase;
  color: $blue;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  border: 1px solid #efefef;
  padding: 15px 15px 15px 40px;
  top: -10px;
  transition: ease-out .18s;
  -webkit-transition: ease-out .18s;
  -moz-transition: ease-out .18s;
  -ms-transition: ease-out .18s;
  -o-transition: ease-out .18s;

  @include breakpoint(xs) {
    margin-right: 0;
    margin-bottom: 10px;
    display: block;
    top: 0;
    width: 100%;
  }

  &:hover {
    text-decoration: none;
    color: white;
    background: $blue;
    transition: ease-out .18s;
    -webkit-transition: ease-out .18s;
    -moz-transition: ease-out .18s;
    -ms-transition: ease-out .18s;
    -o-transition: ease-out .18s;
  }

  &:before {
    content: "\f456";
    font-family: 'Ionicons';
    position: absolute;
    left: 18px;
    top: 50%;
    @include centerY(-50%);
    font-size: 23px;
    color: #b2b9bd;
  }
}

// LOADER

.loader {
  background: white;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow: hidden;
}

.loader svg {
  left: 50%;
  top: 50%;
  position: absolute;
  @include center(-50%, -50%);
}

.loader #spinner {
  box-sizing: border-box;
  stroke: #1af;
  stroke-width: 3px;
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: line 1.6s cubic-bezier(0.4, 0, 0.2, 1) infinite, rotate 1.6s linear infinite;
  animation: line 1.6s cubic-bezier(0.4, 0, 0.2, 1) infinite, rotate 1.6s linear infinite;
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}

@-webkit-keyframes line {
  0% {
    stroke-dasharray: 2, 85.964;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  50% {
    stroke-dasharray: 65.973, 21.9911;
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dasharray: 2, 85.964;
    stroke-dashoffset: -65.973;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@keyframes line {
  0% {
    stroke-dasharray: 2, 85.964;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  50% {
    stroke-dasharray: 65.973, 21.9911;
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dasharray: 2, 85.964;
    stroke-dashoffset: -65.973;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

.loader-after {
  display: none;
  z-index: -999;
  opacity: 0;
}



@media (max-width: 449px) {
  .video-container {  
    iframe {
      height: 200px !important;
    }
  }
}

@media (max-width: 992px) {
  .video-container {
    display: flex;
    flex-direction: column;
    .left {
      height: 50% !important;
    }
    .right {
      height: 50% !important;
    }
  }


  .navbar-header {
    float: none;
  }


  .navbar-left,
  .navbar-right {
    float: none !important;
  }

  .navbar-toggle {
    display: block;
  }

  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    z-index: 10;
    overflow-x: hidden;
  }

  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }

  .navbar-collapse.collapse {
    display: none !important;
    z-index: 10;
    overflow: hidden !important;
  }

  .navbar-nav {
    float: none !important;
    margin-top: 7.5px;
  }

  .navbar-nav>li {
    float: none;
  }

  .navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .collapse.in {
    display: block !important;
  }

  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }
}


.tooltip {
  z-index: 9999;
  position: absolute;

  .tooltip-inner {
    background: white;
    color: $blue;
    text-transform: uppercase;
    border: 1px solid #efefef;
  }
}



* {
  outline: none;
}

html,
body {
  height: 100%;
  width: 100%;
}

body {
  overflow-y: hidden;
}

a,
a:hover,
a:active,
a:visited,
a:focus {
  cursor: pointer;
  text-decoration: none;
}

h1 {
  font-size: 60px;
}

h2 {
  font-size: 46px;
}

p {
  color: #93959d;
  font-size: 14px;
}

strong {
  font-weight: 700;
}

.border-none {
  border: none !important;
}

.hidden-xxs {
  @include breakpoint(xxs) {
    display: none;
  }
}

.fullheight {
  height: 100%;

  .ion-ios-settings-strong {
    &:before {
      position: absolute;
      top: 50%;
      @include centerY(-50%);
    }
  }
}

.white-txt {
  color: #fff !important;
}

.text-center {
  text-align: center !important;
}

.pagination {
  li {
    span {
      box-shadow: none !important;
    }
  }
}

.fx {
  display: flex;
  flex-wrap: wrap;

  .flex {
    display: flex;
    justify-content: center;

    .content {
      background: #FFF;
      border: 1px solid #d9dadc;
      margin-bottom: 30px;
      padding: 10px 0px 0 0px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      // max-width: 258px;
      a {
        width: 100%;
      }

      img {
        max-width: 100%;
      }

      h3 {
        color: #434a54;
        font-size: 18px;
        margin: 10px 0 15px 0;
        padding: 0 15px;
        line-height: 22px;
        font-weight: 300;
      }

      p {
        font-size: 13px;
        color: #1773b6;
        margin-bottom: 20px;
      }

      .btn-int {
        // border-top: 1px solid #d8dadb;
        color: #383838;
        display: block;
        font-size: 15px;
        font-weight: bold;
        padding: 15px 0;
        border-radius: 0;
        position: relative;
        transition: ease-out .18s;
        -webkit-transition: ease-out .18s;
        -moz-transition: ease-out .18s;
        -ms-transition: ease-out .18s;
        -o-transition: ease-out .18s;

        &:before {
          content: "";
          position: absolute;
          top: -2px;
          left: 50%;
          @include centerX(-50%);
          width: 90%;
          height: 1px;
          background: #d8dadb;
        }

        &:hover {
          background: #1958ae;
          color: #fff;
          transition: ease-out .18s;
          -webkit-transition: ease-out .18s;
          -moz-transition: ease-out .18s;
          -ms-transition: ease-out .18s;
          -o-transition: ease-out .18s;
        }

      }
    }

  }


}




.overlay-offcanvas {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1062;
  display: none;
}

.back-color {
  text-transform: uppercase;
  border: 1px solid $blue;
  padding: 5px 10px;
  width: 95px;
  text-align: right;
  float: right;
  color: $blue;
  transition: ease-out .18s;
  -webkit-transition: ease-out .18s;
  position: relative;

  i {
    margin-right: 5px;
    font-size: 22px;
    position: absolute;
    top: 50%;
    @include centerY(-50%);
    left: 10px;
  }

  &:hover {
    color: white;
    background: $blue;
    transition: ease-out .18s;
    -webkit-transition: ease-out .18s;

    i {
      left: 8px;
      transition: ease-out .18s;
      -webkit-transition: ease-out .18s;
    }
  }
}

.flex-row {
  display: flex;
  flex-wrap: wrap;

  &:before {
    display: block;
  }
}

.breadcrumb-parent {
  background: #f5f5f5;
  margin-top: 102px;

  @include breakpoint(xs) {
    //margin-top: 72px;
  }

  .breadcrumb {
    margin: 0;
    border-bottom: 1px solid #efefef;
    padding: 2px 15px;

    li {
      font-family: 'Roboto', sans-serif;

      a {
        color: $blue;
      }
    }
  }
}

.btn-custom {
  background: none;
  border: 2px solid #FFF;
  color: #FFF;
  display: inline-block;
  font-size: 11px;
  padding: 15px 40px;
  text-align: center;
  text-transform: uppercase;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  z-index: 0;

  &:after {
    content: "";
    background: #fff;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    height: 100%;
    width: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
  }

  &:hover,
  &:active {
    color: $blue;

    &:after {
      width: 100%;
    }

  }

  @include breakpoint (xxs) {
    font-size: 12px;
    padding: 8px 40px;
  }

}

.btn-custom-2 {
  border: 1px solid $blue;
  border-radius: 2px;
  color: $blue;
  display: inline-block;
  font-size: 16px;
  margin: 0 0 40px 95px;
  padding: 15px 50px;
  text-transform: uppercase;
  position: relative;
  z-index: 0;

  &:after {
    content: "";
    background: $blue;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    height: 0;
    width: 100%;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
  }

  &:hover,
  &:active {
    color: #fff;

    &:after {
      height: 100%;
    }

  }

  @include breakpoint (xxs) {
    font-size: 12px;
    padding: 8px 40px;
  }

}

.btn-custom-3 {
  border: 1px solid white;
  border-radius: 2px;
  color: white;
  display: inline-block;
  font-size: 16px;
  margin-top: 15px;
  padding: 15px 50px;
  text-transform: uppercase;
  position: relative;
  z-index: 0;

  &:after {
    content: "";
    background: white;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    height: 0;
    width: 100%;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
  }

  &:hover,
  &:active {
    color: $blue;

    &:after {
      height: 100%;
    }

  }

  @include breakpoint (xxs) {
    font-size: 12px;
    padding: 8px 40px;
  }

}

.btn-custom-4 {
  border: 1px solid #515151;
  border-radius: 2px;
  color: #515151;
  display: inline-block;
  font-size: 16px;
  margin: 10px 0;
  padding: 15px 50px;
  text-transform: uppercase;
  position: relative;
  z-index: 0;

  &:after {
    content: "";
    background: #515151;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    height: 0;
    width: 100%;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
  }

  &:hover,
  &:active {
    color: #fff;
    background-color: #515151;
    border: 1px solid #515151;

    &:after {
      height: 100%;
    }
  }

  @include breakpoint (xxs) {
    font-size: 12px;
    padding: 8px 40px;
    border: 1px solid white;
    color: white;
  }
}

.btn-custom-5 {
  background-color: white;
  border: 1px solid #4f4f4f;
  border-radius: 2px;
  color: #4f4f4f;
  display: inline-block;
  font-size: 16px;
  margin: 0 0 40px 95px;
  padding: 15px 50px;
  text-transform: uppercase;
  position: relative;
  z-index: 0;

  &:after {
    content: "";
    background: $blue;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    height: 0;
    width: 100%;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
  }

  &:hover,
  &:active {
    color: #fff;

    &:after {
      height: 100%;
    }

  }

  @include breakpoint (xxs) {
    font-size: 12px;
    padding: 8px 40px;
  }

}

.modal-backdrop {
  z-index: 1061 !important;
}

.modal {
  z-index: 1062 !important;
}

#modalFichaTecnica {
  .modal-dialog {
    margin-top: 102px;
    max-height: 80vh;
    overflow-y: scroll;
  }

  .modal-content {
    border-radius: 0;

    .modal-header {
      background: $blue;
      position: relative;

      @include breakpoint(xs) {
        text-align: center;
      }

      h1 {
        text-transform: uppercase;
        border: 1px solid white;
        color: white;
        font-size: 16px;
        padding: 10px;
        font-weight: 500;
        display: inline-block;

        span {
          font-weight: 300;
        }

        @include breakpoint(xs) {
          text-align: center;
          border: none;
          display: block;
          margin-top: 10px;
        }
      }

      h2 {
        text-transform: uppercase;
        color: white;
        font-size: 20px;
        font-weight: 500;

        span {
          font-weight: 300;
        }

        @include breakpoint(xs) {
          text-align: center;
          margin-top: 10px;
        }
      }

      .logo-modal {
        max-width: 100%;

        @include respond-to(992) {
          position: absolute;
          right: 15px;
          top: 50%;
          @include centerY(-50%);
        }

        @include breakpoint(sm) {
          text-align: center;
          width: 85px;
          margin-top: 10px;
        }
      }

      .logo-modal-dt {
        width: 55px;
        right: 22%;
      }
    }

    .modal-body {
      .producto-modal {
        text-align: center;

        @include breakpoint(sm) {
          margin-bottom: 20px;
        }

        img {
          max-width: 100%;

          @include breakpoint(sm) {
            max-width: 50%;
          }

          @include breakpoint(xs) {
            max-width: 100%;
          }
        }
      }

      h1 {
        text-transform: uppercase;
        font-size: 20px;
        color: $blue;
      }
    }

    .modal-footer {
      background: #efefef;
      text-align: left;
      font-size: 12px;

      .download-ft {
        border-radius: 0;
        font-size: 12px;
        color: #333333;
        background: transparent;

        @include respond-to(768) {
          position: absolute;
          right: 15px;
          bottom: 15px;
        }

        @include breakpoint(xs) {
          width: 100%;
          position: relative;
        }

        &:focus,
        &:active {
          outline: none;
          box-shadow: none;
        }
      }
    }
  }
}

/*=====================================================================================
                                  FICHA TECNICA
=====================================================================================*/

.ficha_tecnica {
  border-radius: 0;
  width: 100%;
  max-width: 800px;
  margin: auto;

  .ficha_header {
    background: $blue;
    position: relative;
    padding: 10px;

    @include breakpoint(xs) {
      text-align: center;
    }

    h1 {
      text-transform: uppercase;
      border: 1px solid white;
      color: white;
      font-size: 16px;
      padding: 10px;
      font-weight: 500;
      display: inline-block;

      span {
        font-weight: 300;
      }

      @include breakpoint(xs) {
        text-align: center;
        border: none;
        display: block;
        margin-top: 10px;
      }
    }

    h2 {
      text-transform: uppercase;
      color: white;
      font-size: 20px;
      font-weight: 500;

      span {
        font-weight: 300;
      }

      @include breakpoint(xs) {
        text-align: center;
        margin-top: 10px;
      }
    }

    .logo-modal {
      max-width: 100%;

      @include respond-to(992) {
        position: absolute;
        right: 15px;
        top: 50%;
        @include centerY(-50%);
      }

      @include breakpoint(sm) {
        text-align: center;
        width: 85px;
        margin-top: 10px;
      }
    }

    .logo-modal-dt {
      width: 55px;
      right: 22%;
    }
  }

  .ficha_body {
    padding: 10px;

    .producto-modal {
      text-align: center;

      @include breakpoint(sm) {
        margin-bottom: 20px;
      }

      img {
        max-width: 100%;

        @include breakpoint(sm) {
          max-width: 50%;
        }

        @include breakpoint(xs) {
          max-width: 100%;
        }
      }
    }

    h1 {
      text-transform: uppercase;
      font-size: 20px;
      color: $blue;
    }
  }

  .ficha_footer {
    background: #efefef;
    text-align: left;
    font-size: 12px;
    padding: 10px;

    .download-ft {
      border-radius: 0;
      font-size: 12px;
      color: #333333;
      background: transparent;

      @include respond-to(768) {
        position: absolute;
        right: 15px;
        bottom: 15px;
      }

      @include breakpoint(xs) {
        width: 100%;
        position: relative;
      }

      &:focus,
      &:active {
        outline: none;
        box-shadow: none;
      }
    }
  }
}

@media print {
  .ficha_tecnica {
    border-radius: 0;
    width: 100%;
    max-width: 800px;
    margin: auto;

    .ficha_header {
      background: $blue;
      position: relative;
      padding: 10px;

      @include breakpoint(xs) {
        text-align: center;
      }

      h1 {
        text-transform: uppercase;
        border: 1px solid white;
        color: white;
        font-size: 16px;
        padding: 10px;
        font-weight: 500;
        display: inline-block;

        span {
          font-weight: 300;
        }

        @include breakpoint(xs) {
          text-align: center;
          border: none;
          display: block;
          margin-top: 10px;
        }
      }

      h2 {
        text-transform: uppercase;
        color: white;
        font-size: 20px;
        font-weight: 500;

        span {
          font-weight: 300;
        }

        @include breakpoint(xs) {
          text-align: center;
          margin-top: 10px;
        }
      }

      .logo-modal {
        max-width: 100%;

        @include respond-to(992) {
          position: absolute;
          right: 15px;
          top: 50%;
          @include centerY(-50%);
        }

        @include breakpoint(sm) {
          text-align: center;
          width: 85px;
          margin-top: 10px;
        }
      }

      .logo-modal-dt {
        width: 55px;
        right: 22%;
      }
    }

    .ficha_body {
      padding: 10px;

      .producto-modal {
        text-align: center;

        @include breakpoint(sm) {
          margin-bottom: 20px;
        }

        img {
          max-width: 100%;

          @include breakpoint(sm) {
            max-width: 50%;
          }

          @include breakpoint(xs) {
            max-width: 100%;
          }
        }
      }

      h1 {
        text-transform: uppercase;
        font-size: 20px;
        color: $blue;
      }
    }

    .ficha_footer {
      background: #efefef;
      text-align: left;
      font-size: 12px;
      padding: 10px;

      .download-ft {
        border-radius: 0;
        font-size: 12px;
        color: #333333;
        background: transparent;

        @include respond-to(768) {
          position: absolute;
          right: 15px;
          bottom: 15px;
        }

        @include breakpoint(xs) {
          width: 100%;
          position: relative;
        }

        &:focus,
        &:active {
          outline: none;
          box-shadow: none;
        }
      }
    }
  }
}

/*=====================================================================================
                                  FIN FICHA TECNICA
=====================================================================================*/


.modal {
  text-align: center;
  padding: 0 !important;
}

.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.pad-wrp {
  padding: 50px 0;
}

.btn-blue {
  background: #1958ae;
  color: #fff;
  text-transform: uppercase;
  font-weight: 300;
  padding: 10px 50px;

  &:hover {
    background: #2571d9;
    color: #fff;
  }

}

.arrow-select .form-control-feedback {
  color: #000;
  opacity: 1;
}



// Header
.navbar-toggle {
  display: none;

  @include breakpoint(sm) {
    display: block !important;
  }

  @include breakpoint(xs) {
    margin: 19px 10px 0 0 !important;
  }
}

#navbar-main {
  /* Added `.affix-top` class css when the element is top */
  z-index: 1061;
  transition: all 0.5s ease;
  position: absolute;
  width: 100%;
  background: white;

  &.affixed-top {}

  /* Added `.affix` class css when the element is >= 170px (Look: line 8 html) */
  &.affix {
    background: white;
    top: 0;
    left: 0;
    right: 0;
    position: fixed !important;
  }

  /* Added `.affix-bottom` class css when the element is footer */
  &.affix-bottom {}
}


header {
  font-family: $montserrat;

  .top {
    background: $blue;
    border-bottom: 2px solid #1958ae;
    padding: 5px 0;

    .interactua {
      padding: 0 30px;
    }

    a {
      color: $blue;
      font-size: 12px;
      margin: 0 5px;

      i {
        color: #1958ae;
        font-size: 20px;
        margin-top: 10px;
        transition: ease-out .18s;
        -webkit-transition: ease-out .18s;
        -moz-transition: ease-out .18s;
        -ms-transition: ease-out .18s;
        -o-transition: ease-out .18s;

        &:hover {
          color: white;
          transition: ease-out .18s;
          -webkit-transition: ease-out .18s;
          -moz-transition: ease-out .18s;
          -ms-transition: ease-out .18s;
          -o-transition: ease-out .18s;
        }
      }
    }

    .text-right {
      a {
        background: #b8cde9;
        display: inline-block;
        line-height: 40px;
        margin: 0;
        padding: 0 20px 0 15px;
        text-transform: uppercase;

        i {
          color: $blue;
          font-size: 16px;
          margin-right: 10px;
        }

        &:hover {
          background: #fff;
          -webkit-transition: all 0.3s;
          -moz-transition: all 0.3s;
          transition: all 0.3s;
        }
      }
    }
  }

  .main-menu-top {
    border-bottom: 2px solid $blue;
  }

  .navbar-brand {
    height: 50px;
    margin-top: 25px;
    padding: 0;
    text-indent: -9999px;
    width: 155px;

    @include breakpoint(md) {
      margin-top: 29px;
      width: 130px;
    }

    @include breakpoint(xs) {
      margin-top: 15px;
    }

    img {
      @include breakpoint(xs) {
        width: 80%;
        margin: auto;
      }
    }
  }

  nav.navbar {
    border: none;
    border-radius: 0;
    font-size: 12px;
    height: 100px;
    margin: 0;
    text-transform: uppercase;

    @include breakpoint(xs) {
      height: 70px;
    }

    .navbar-collapse {
      @include breakpoint(xs) {
        top: 19px;
      }
    }

    .dropdown-menu li>a:hover,
    .dropdown-menu li>a:focus {
      box-shadow: none;
    }

    .navbar-nav {
      // border-bottom: 2px solid $blue;
      margin-bottom: -4px;

      .dropdown-menu {
        right: inherit !important;
      }

      >li {

        >a {
          color: #4e4e4e;
          line-height: 100px;
          padding: 0 10px;

          @include breakpoint(md) {
            padding: 0 5px;
          }

          i {
            font-size: 16px;
            color: #3c76c4;
            margin-left: 5px;
          }

          .fa-angle-right {
            display: none;
          }

        }

        &.active {
          border-bottom: 2px solid #d61300;
        }
      }
    }
  }

  .pull-right {
    margin-left: 20px;
    position: relative;

    a {
      color: #b2b9bd;
      border-right: 1px solid #dde2e4;
      display: inline-block;
      font-size: 10px;
      height: 100px;
      padding-top: 20px;
      text-align: center;
      width: 100px;

      @include breakpoint(md) {
        width: 85px;
      }

      i {
        font-size: 24px;
        margin-top: 18px;
      }
    }

    .search-pint {
      border-left: 1px solid #dde2e4;
    }


  }
}

.black {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.top-title {
  background-image: url(../images/bg-rse.jpg);
  background-size: cover;
  background-position-x: right !important;
  background-repeat: no-repeat;
  padding: 120px 0;
  position: relative;
  text-align: center;
  border-bottom: 5px solid #d81d04;

  h1 {
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 60px;
    position: relative;
    padding-bottom: 15px;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      border-top: 1px solid #fff;
      width: 50px;
    }

  }

  p {
    color: #fff;
    font-size: 18px;
    position: relative;
    z-index: 10;
  }

}

.gris {
  background-color: #f8f8f8;
  padding: 80px 0;

  .row {
    padding: 0 70px;
  }

  p {
    color: #4379c2;
    text-align: justify;
  }
}

.hovereffect {
  border: 1px solid #E8E8E8;
  width: 100%;
  height: 100%;
  float: left;
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor: default;


  img {
    display: block;
    position: relative;
  }

  h2 {
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    position: relative;
    font-size: 17px;
    padding: 10px;
    background: rgba(0, 0, 0, 0.6);
    -webkit-transform: translateY(45px);
    -ms-transform: translateY(45px);
    transform: translateY(45px);
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }

  p.info {
    display: inline-block;
    text-decoration: none;
    text-align: justify;
    padding: 7px 14px;
    color: #fff;
    background-color: transparent;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: translateY(45px);
    -ms-transform: translateY(45px);
    transform: translateY(45px);
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    font-weight: normal;
    margin: 7px;

    @include breakpoint(md) {
      font-size: 13px;
    }

    @include breakpoint(sm) {
      font-size: 14px;
    }

    &:before {
      display: none;
    }

    .title {
      display: block;
      text-align: center;
      font-weight: 700;
      font-size: 20px;
      padding: 15px 0;
    }
  }

  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;

    .btn-transparent {
      border: 1px solid #fff;
      background: transparent;
      font-weight: 300;
      color: #fff;
      border-radius: 0;
      padding: 10px 20px;
      margin-top: 20px;
      font-size: 12px;

      &:hover {
        background: #fff;
        color: $blue;
      }
    }
  }

  &:hover {
    .overlay {
      background-color: rgba(58, 116, 195, 1);
    }

    h2 {
      -webkit-transform: translateY(5px);
      -ms-transform: translateY(5px);
      transform: translateY(5px);
      margin: 0px;
    }

    p.info {
      opacity: 1;
      filter: alpha(opacity=100);
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }
  }
}

.grid-social {
  padding: 50px 0;

  .hovereffect {
    padding: 60px 0;
  }

  .col-md-4 {
    padding: 0;
  }
}

.collage {


  .gall {
    padding: 0;
    position: relative;
    min-height: 320px;

    .hovereffect {
      position: absolute;
    }

  }
}

#buscador-pinturerias {
  .usar-ubicacion {
    color: $blue;
    text-transform: uppercase;
    font-size: 10px;
    border: none;
    text-align: left;
    width: 100%;
    height: auto;
    position: relative;
    padding-top: 0;
    margin-top: 15px;
    margin-left: 30px;

    .ripple-container {
      width: 0 !important;
      height: 0 !important;
    }

    &:before {
      content: "\f2e9";
      font-family: 'Ionicons';
      position: absolute;
      top: 50%;
      left: -25px;
      @include centerY(-50%);
      color: $blue;
      font-size: 22px;
    }
  }

  input[type="submit"] {
    background: $blue;
  }
}

.buscador {
  background: #FFF;
  display: none;
  margin: 2px 1px 0 0;
  max-width: 510px;
  padding: 30px 20px;
  position: absolute;
  right: 0;
  width: 510px;
  z-index: 18;
  border: 1px solid #efefef;

  .nav-tabs {
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom-color: $blue;
    overflow: hidden;

    li {
      a {
        border: none;
        height: auto;
        width: auto;
        padding: 5px 20px;

        &:hover {
          background-color: transparent;
        }

        &:before {
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          background-color: $blue;
          bottom: -21px;
          left: 50%;
          transform: translateX(-50%) rotate(45deg);
          -webkit-transform: translateX(-50%) rotate(45deg);
          -moz-transform: translateX(-50%) rotate(45deg);
          -ms-transform: translateX(-50%) rotate(45deg);
          -o-transform: translateX(-50%) rotate(45deg);

          transition: ease-out .2s;
          -webkit-transition: ease-out .2s;
          -moz-transition: ease-out .2s;
          -ms-transition: ease-out .2s;
          -o-transition: ease-out .2s;
        }
      }

      &.active {
        a {
          border: none;
          background-color: transparent;
          color: $blue;

          &:hover {
            border: none;
          }

          &:before {
            content: "";
            bottom: -14px;
            transition: ease-out .2s;
            -webkit-transition: ease-out .2s;
            -moz-transition: ease-out .2s;
            -ms-transition: ease-out .2s;
            -o-transition: ease-out .2s;
          }
        }
      }
    }
  }

  .tab-content {
    .tab-pane {
      input[type="submit"] {
        width: auto;
        padding: 5px 30px;
        float: right;
        color: white !important;
      }

      .input-group {
        .twitter-typeahead {
          width: 100%;

          input {
            width: 100%;
          }
        }
      }
    }
  }

  .form-inline {
    padding: 0 15px;
    margin: 0 0 20px 0;

    .form-group {
      width: 100%;
      margin: 0;

      .input-group {
        width: 100%;
        border-radius: 5px;

        input.form-control {
          margin: 0;
          background-size: 0 2px, 100% 0px;
          padding: 0 15px;
          color: $blue;
          text-transform: none;
        }

        ::placeholder {
          color: #b2b9bd;
          font-size: 10px;
        }

        .input-group-addon {
          padding: 10px 5px;
          background: $blue;
          color: white;
          cursor: pointer;
        }
      }
    }
  }

  h3 {
    border-bottom: 1px solid #f0f0f0;
    color: #8cb5ec;
    font-size: 14px;
    margin: 0 0 12px;
    padding-bottom: 25px;
    text-align: center;
  }

  .active {
    color: $blue;

    select,
    input[type="text"] {
      border: 1px solid $blue !important;
      text-transform: uppercase;
    }

    input[type="submit"] {
      background: $blue !important;
    }


  }

  .col-md-6 {
    color: #888;
    text-transform: none;

    input[type="text"] {
      background: transparent;
      border: 1px solid #ccc;
      color: #333;
      font-size: 10px;
      margin-bottom: 15px;
      padding: 0 10px;
    }

    select {
      text-transform: uppercase;
    }

    label {
      color: #b2b9bd;
      text-transform: uppercase;
    }

    input[type="submit"] {
      background: #b2b9bd;
    }

    &:first-child {
      border-right: 1px solid #e5e5e5;
    }

    &:last-child {
      .line {
        margin-top: 75px;
      }
    }
  }

  input {
    border: none;
    color: #666;
    font-size: 12px;
    height: 40px;
    text-transform: uppercase;
    width: 100%;
    padding: 10px;
    letter-spacing: 1px;
  }

  select {
    height: 40px;
    font-size: 10px;
    margin-bottom: 25px;
    width: 100%;
    padding: 10px;
    color: #666;
    letter-spacing: 1px;
  }

  label {
    display: inline-block;
    font-size: 10px;
    margin-bottom: 20px;
  }

  .line {
    background: #e5e5e5;
    height: 1px;
    margin-bottom: 25px;
    width: 100%;
  }
}

.buscador-tab {
  padding: 10px 20px;
  width: 100%;

  .col-md-12:last-child {
    color: #888;
    text-transform: none;
    padding: 0;

    input[type="text"] {
      background: transparent;
      border: 1px solid #ccc;
      color: #333;
      font-size: 10px;
      margin-bottom: 15px;
      padding: 0 10px;
    }

    label {
      color: #b2b9bd;
      text-transform: uppercase;
    }

    input[type="submit"] {
      background: #b2b9bd;
    }

    .line {
      margin-top: 8px;
    }

  }

  input {
    border: none;
    color: #FFF;
    font-size: 14px;
    height: 40px;
    text-transform: uppercase;
    width: 100%;
  }

  select {
    height: 40px;
    font-size: 10px;
    margin-bottom: 25px;
    width: 100%;
  }

  label {
    display: inline-block;
    font-size: 10px;
    margin-bottom: 20px;
  }

  .line {
    background: #e5e5e5;
    height: 1px;
    margin-bottom: 25px;
    width: 100%;
  }
}

#exTab3 {
  .nav-pills>li {
    margin: 0;
    width: 50%;

    >a {
      border-radius: 0;
      color: #b2b9bd;
      border-right: 1px solid #dde2e4;
      display: inline-block;
      font-size: 10px;
      height: 80px;
      text-align: center;
      outline: none;
      width: 100%;

      i {
        font-size: 24px;
        margin-top: 10px;
      }

      &.active,
      &:hover,
      &:focus {
        background: #F8F9FA;
      }
    }

    &.active {
      background: #F8F9FA;

      >a {
        background: #F8F9FA;
      }
    }

  }


  .tab-content {
    color: white;
    background-color: #F8F9FA;
    padding: 5px 0px;

    #pinturerias-mobile {}
  }
}

// Carousel home

#owl-home {
  .owl-controls {
    .owl-dots {
      display: inline-block;
      height: 20px;
      position: absolute;
      bottom: 20px;
      left: 10%;

      @include breakpoint(sm) {
        left: 50%;
        @include centerX(-50%);
      }

      .owl-dot {
        background: transparent;
        border: 3px solid white;
        border-radius: 20px;
        margin: 0 5px;
        display: inline-block;
        width: 15px;
        height: 15px;
        -webkit-box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.29);
        -moz-box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.29);
        box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.29);
      }

      .active {
        border-color: $blue;
      }
    }
  }

  .owl-item {
    @include breakpoint(xxs) {
      //height: 480px !important;
    }

    .owl-item-3 {
      @include breakpoint(sm) {
        background-image: none !important;
        background-color: #fff !important;
      }

      .content {
        @include breakpoint(xs) {
          width: 100%;
          height: 100vh;
        }

        h1 {
          font-size: 33px !important;
          line-height: 30px !important;

          span {
            font-size: 30px !important;
          }
        }
      }
    }

    .last-item {
      @include breakpoint(sm) {
        background-image: url(../images/slide-home-4-mob.jpg) !important;
      }

      @media (max-height: 580px) {
        height: 438px !important;
      }
    }

    .item-owl-home {
      height: calc(100vh - 152px);
      //margin-top: 102px;
      position: relative;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      overflow: hidden;

      // @include respond-to(992){
      //   min-height: 600px;
      // }
      @include breakpoint(xs) {
        height: calc(100vh - 134px);
        //margin-top: 72px;
      }

      @media (max-height: 580px) {
        height: 100%;
        padding-bottom: 40px;
      }

      .container {
        position: relative;
        height: 100%;
      }

      .content {
        @media (min-height: 580px) {
          position: absolute;
        }

        text-align: center;

        @media (min-width: 992px) and (min-height: 581px) {
          right: 50px;
          top: 50%;
          @include centerY(-50%);
        }

        @media (max-width: 991px) and (min-height: 581px) {
          top: 50%;
          left: 50%;
          @include center(-50%, -50%);
        }

        h1 {
          font-size: 50px;
          text-transform: uppercase;
          color: $blue;
          font-family: 'Montserrat', sans-serif;
          font-weight: 700;
          line-height: 38px;

          @include breakpoint(xs) {
            font-size: 35px;
            line-height: 30px;
          }

          span {
            font-size: 40px;
            color: #dd3523;
            font-weight: 400;
            letter-spacing: 2px;

            @include breakpoint(xs) {
              font-size: 30px;
            }
          }
        }

        h2 {
          font-size: 20px;
          text-transform: uppercase;
          color: #bba285;
          letter-spacing: 2px;

          @include breakpoint(xxs) {
            letter-spacing: 1px;
            font-size: 18px;
          }
        }

        .tarro-img-slide {
          width: 100%;
          max-width: 390px;
          margin: auto;

          @media (max-height: 790px) {
            width: 70%;
            margin: auto;
          }

          // @media (max-height: 700px){
          //   display: none;
          // }
          @include breakpoint(xs) {
            width: 70%;
            margin: auto;
          }

          // @include breakpoint(xxs){
          //   display: none;
          // }
        }

        .tarro-3-width {
          @include breakpoint(xs) {
            width: 35%;
          }
        }

        .cards-slide {
          text-align: center;
          margin-top: 40px;

          ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: space-between;
            margin: auto;

            li {
              display: inline-block;
              text-align: center;
              margin: 10px;

              img {
                margin: auto;
                max-width: 86px;
              }

              p {
                text-transform: uppercase;
                color: $blue;
                letter-spacing: 2px;
                font-size: 12px;
                margin-top: 15px;
              }
            }
          }
        }

        .btn-custom-2 {
          margin: 20px auto;

          @include breakpoint(xs) {
            font-size: 14px;
          }
        }

        .elegi-logo-slider {
          width: 100%;
          max-width: 195px;
          margin: 30px auto 0;

          @media (max-height: 700px) {
            display: none;
          }

          @include breakpoint(xxs) {
            display: none;
          }
        }
      }

      .pajaro-1 {
        position: absolute;
        bottom: -5px;
        left: -70px;
        width: 100%;
        max-width: 685px;

        @media (max-height: 700px) {
          max-width: 540px !important;
        }

        @include breakpoint(lg) {
          left: 0;
        }

        @include breakpoint(md) {
          bottom: 0;
          left: -100px;
          max-width: 620px;
        }

        @include breakpoint(sm) {
          display: none;
        }
      }

      .romano-1 {
        width: 100%;
        position: absolute;
        top: 50%;
        left: -90px;
        @include centerY(-50%);
        max-width: 685px;

        @media (max-height: 700px) {
          max-width: 540px !important;
        }

        @include breakpoint(lg) {
          left: 0;
          max-width: 575px;
        }

        @include breakpoint(md) {
          bottom: inherit;
          max-width: 460px;
        }

        @include breakpoint(sm) {
          display: none;
        }
      }

      .tarro-me {
        width: 100%;
        position: absolute;
        top: 59%;
        left: -90px;
        @include centerY(-50%);
        max-width: 685px;

        @media (max-height: 700px) {
          max-width: 540px !important;
        }

        @include breakpoint(lg) {
          left: 0;
          max-width: 575px;
          top: 66%;
        }

        @include breakpoint(md) {
          bottom: inherit;
          max-width: 460px;
          top: 73%;
        }

        @include breakpoint(sm) {
          display: none;
        }
      }
    }

    .video {
      background-image: url('../images/video-bg.jpg');
      background-position: center center;
      background-size: cover;
      .video-container {
        height: 100%;
        .left {
          height: 100%;
          display: flex;
          justify-content: center;
          flex-direction: column;
          h3, h2, p {
            color: black;
          }
          h3 {
            font-size: 14px;
            text-transform: uppercase;
            margin-bottom: 0px;
          }
          h2 {
            margin-top: 10px;
            font-weight: 700;
          }
          p {
            font-size: 17px;
            line-height: 32px;
          }
        }
        .right {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          iframe {
            box-shadow: 0 1rem 3rem rgba(0,0,0,.4)!important;
            border-radius: 5px;
          }
        }
      }
    }

    .xtreme {
      @include breakpoint(sm) {
        background-position-x: right !important;
      }

      .content {
        @include breakpoint(xs) {
          width: 60%;
          margin: auto;
        }

        @include breakpoint(xxs) {
          width: 90%;
        }

        .xtreme-logo {
          width: 285px;
          margin: 0 auto;

          @include breakpoint(xs) {
            width: 200px;
          }
        }

        .me-aplicaciones {
          margin: 45px auto;
          width: 450px;

          @include breakpoint(sm) {
            width: 100%;
          }
        }

        p {
          color: #d91013;
          font-size: 24px;
          font-family: 'Montserrat', sans-serif;
          font-weight: 600;

        }

        h2 {
          font-size: 20px;
          text-transform: uppercase;
          color: #515151;
          letter-spacing: 2px;

          @include breakpoint(xxs) {
            letter-spacing: 1px;
            font-size: 18px;
          }
        }
      }

      .color-samples {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        @include breakpoint(xxs) {
          padding-bottom: 60px;
        }

        .sample {
          text-align: center;
          width: 100%;
          display: inline-block;

          .bar {
            height: 14px;

          }

          p {
            text-transform: uppercase;
            font-size: 20px;
            font-family: 'Montserrat', sans-serif;
            font-weight: 600;

            @include breakpoint(md) {
              font-size: 12px;
              padding-top: 10px;
            }

            @include breakpoint(xxs) {
              display: none;
            }
          }
        }

        .violet {
          p {
            color: #9231a8;
          }

          .bar {
            background-color: #9231a8;
          }
        }

        .blue {
          p {
            color: #0028ab;
          }

          .bar {
            background-color: #0028ab;
          }
        }

        .light-blue {
          p {
            color: #00b7e7;
          }

          .bar {
            background-color: #00b7e7;
          }
        }

        .light-green {
          p {
            color: #00afbf;
          }

          .bar {
            background-color: #00afbf;
          }
        }

        .green {
          p {
            color: #79c800;
          }

          .bar {
            background-color: #79c800;
          }
        }

        .orange {
          p {
            color: #ff850e;
          }

          .bar {
            background-color: #ff850e;
          }
        }

        .red {
          p {
            color: #ff3c32;
          }

          .bar {
            background-color: #ff3c32;
          }
        }
      }

    }


    .colores {
      background-image: url(../images/banner_colores_2.gif);
      background-size: cover;    

      @include breakpoint(md) {
        background-image: url(../images/banner_colores_mobile_2.gif);
        background-position: top;
        background-color: #88cafa;
        background-size: cover;
      }
    }

    .diluyente {
      background-image: url(../images/diluyentes-slider.jpg);
      background-position-x: 25%;
      background-size: cover;    

      @include breakpoint(md) {
        background-image: url(../images/diluyentes-slider-r.jpg);
        background-position: center;
      }

      .content {
        top: 70%;
        @include breakpoint(xs) {
          width: 60%;
          margin: auto;
          top: 80% !important;
          left: 39% !important;
        }

        @include breakpoint(xxs) {
          width: 60%;
        }
     
      }
    }

    .tersitech {
      background-image: url(../images/Landing_Tersitech-07.png);
      background-size: cover;

      @include breakpoint(md) {
        background-image: url(../images/Slider_Tersitech800x1200_mobile.jpg);
        background-position: center;
        background-size: cover;
      }
    }

    .terplast {
      @include breakpoint(sm) {
        background-position-x: right !important;
      }

      .content {
        @include breakpoint(xs) {
          width: 60%;
          margin: auto;
        }

        @include breakpoint(xxs) {
          width: 90%;
        }

        h2 {
          @media (max-height: 768px) {
            font-size: 16px;
          }

          @include breakpoint(xxs) {
            font-size: 12px;
            margin: 12px 0 0 0;
          }

        }

        .texturas-slider {
          width: 100%;
          margin: 30px auto 0;

          @media (max-height: 768px) {
            width: 75%;
          }
        }

        .tarros-terplast-slider {
          margin: 30px auto;
          width: 450px;

          @include breakpoint(sm) {
            width: 100%;
          }
        }

        .tarro-img-slide {
          margin-top: 20px;

          @include breakpoint(md) {
            max-width: 280px;
          }
        }
      }
    }

    .terplast-2 {
      .content {
        @include breakpoint(md) {
          right: 0;
        }
      }
    }

    .owl-item-3 {
      .text-two {
        text-align: center;

        @media (min-height: 580px) {
          position: absolute;
          left: 50px;
          top: 5%;
        }

        @include breakpoint(sm) {
          display: none;
        }

        h1 {
          color: white;
          text-transform: uppercase;
          font-family: 'Montserrat', sans-serif;
          font-weight: 700;
          font-size: 50px;
          text-align: center;
          text-shadow: 0px 2px 6px black;

          span {
            font-size: 40px;
            font-weight: 400;
          }
        }
      }

      .text-three {
        text-align: center;

        .tarro-4 {
          max-width: 400px;
          width: 100%;
          margin: auto;

          @media (max-height: 780px) {
            display: none;
          }
        }

        h2 {
          color: white;
          font-family: 'Montserrat', sans-serif;
          text-transform: uppercase;
          letter-spacing: 5px;
          text-align: center;
          font-size: 65px;
          text-shadow: 0px 5px 10px black;

          @include breakpoint(md) {
            font-size: 55px;
          }

          @include breakpoint(xxs) {
            font-size: 35px;
          }
        }

        h3 {
          color: white;
          font-family: 'Montserrat', sans-serif;
          text-transform: uppercase;
          letter-spacing: 3px;
          text-align: center;
          font-size: 45px;
          position: relative;
          text-shadow: 0px 5px 10px black;

          @include breakpoint(md) {
            font-size: 40px;
          }

          @include breakpoint(xxs) {
            font-size: 30px;
          }

          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            @include centerY(-50%);
            width: 75px;
            height: 3px;
            background: white;

            @include breakpoint(xxs) {
              width: 30px;
            }
          }

          &:after {
            content: "";
            position: absolute;
            right: 0px;
            top: 50%;
            @include centerY(-50%);
            width: 75px;
            height: 3px;
            background: white;

            @include breakpoint(xxs) {
              width: 30px;
            }
          }
        }

        h4 {
          color: white;
          font-family: 'Montserrat', sans-serif;
          text-transform: uppercase;
          letter-spacing: 2px;
          text-align: center;
          font-size: 21px;
          text-shadow: 0px 5px 10px black;

          @include breakpoint(md) {
            font-size: 18px;
          }

          @include breakpoint(xxs) {
            font-size: 15px;
            letter-spacing: 1px;
          }
        }
      }

      .last-slide {
        @media (min-width: 992px) and (min-height: 581px) {
          top: 50% !important;
          @include centerY(-50%);
          position: absolute;
        }

        @media (max-width: 991px) and (min-height: 581px) {
          display: block !important;
          position: absolute;
          top: 50% !important;
          left: 50% !important;
          @include center(-50%, -50%);
        }

        @include breakpoint (xxs) {
          position: absolute;
          top: 50% !important;
          left: 50% !important;
          @include center(-50%, -50%);
        }
      }

      .content {
        right: 50px;

        @include breakpoint(md) {
          right: 0;
        }

        @include respond-to(992) {
          top: 5%;
          transform: none !important;
        }
      }

      .last-content {
        @include respond-to(992) {
          top: 50%;
          @include centerY(-50%);
        }
      }
    }

    .ubicaciones {
      @include breakpoint(sm) {
        background-position: right !important;
      }

      .content {
        @include breakpoint(sm) {
          width: 100%;
        }
      }

      h1 {
        color: #dd3523 !important;
        font-size: 20px !important;
        letter-spacing: 1px !important;
        font-weight: 300 !important;

        @include breakpoint(sm) {
          font-size: 12px !important;
        }

        span {
          color: $blue !important;
          font-size: 40px !important;
          font-weight: bold !important;

          @include breakpoint(sm) {
            font-size: 25px !important;
          }
        }
      }

      .tarro-img-slide {
        @include breakpoint(xxs) {
          width: 100% !important;
        }
      }

      .mapa-slider {
        position: absolute;
        top: 50%;
        @include centerY(-50%);
        width: 100%;
        max-width: 270px;

        @include breakpoint(sm) {
          display: none;
        }
      }
    }

    .rubik {
      @media (max-height: 580px) {
        height: calc(100vh - 134px) !important;
      }

      .container {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .content {
        @include breakpoint(sm) {
          width: 100%;
        }
      }

      h1,
      h2 {
        color: white !important;

        span {
          font-weight: bold;
        }
      }

      a {
        margin: 30px 0;
      }
    }

    .mucho-gusto {
      @include breakpoint(sm) {
        background-position: right !important;
      }

      .container {
        position: relative;
      }

      .tendencias {
        position: absolute;
        top: 0;
        right: 135px;
        width: 250px;

        @media (max-width: 991px) {
          right: inherit;
          left: 50%;
          @include centerX(-50%);
        }

        @media (max-height: 580px) {
          display: none;
        }
      }

      .mucho-gusto-encantado {
        width: 100%;
        max-width: 400px;
        margin: 70px auto 30px;

        @media (max-height: 650px) {
          max-width: 300px;
          margin: 70px auto 10px;
        }
      }

      .btn-custom-2 {
        color: #636466;
        border-color: #636466;

        &:after {
          background-color: #636466;
        }

        &:hover {
          color: white;
        }
      }

      .hay-color-logo {
        width: 100%;
        max-width: 150px;
        margin: 30px auto 0;

        @media (max-height: 650px) {
          max-width: 120px;
          margin: 0 auto;
        }
      }
    }

    .estilopilar {
      @include breakpoint(sm) {
        background-position-x: right !important;
      }

      @include breakpoint(xxs) {
        background-position: left !important;
      }

      .container {
        .row {
          position: relative;
          height: 100%;

          div[class*='col-'] {
            position: relative;
            height: 100%;
          }
        }
      }

      .content {
        text-align: center;

        @include breakpoint(xs) {
          width: 100%;
        }

        @include breakpoint(xxs) {
          position: absolute;
          top: 50%;
          left: 50%;
          @include center(-50%, -50%);
        }

        h2 {
          font-size: 55px;
          font-weight: 800;
          color: #818181;
          letter-spacing: 2px;
          margin: 0 0 10px 0;
          text-transform: none;

          @include breakpoint(xs) {
            font-size: 27px;
            color: white;

          }
        }

        .logo-pilar {
          margin: 0 auto 40px;
          width: 100%;
          max-width: 320px;

          @include breakpoint(xs) {
            max-width: 250px;
          }
        }

        .logo-tersuave {
          width: 200px;
          margin: 0 auto 15px;

          @include breakpoint(xs) {
            width: 150px;
          }
        }

        span {
          font-size: 25px;
          color: #828282;
          text-transform: uppercase;
          letter-spacing: 1px;

          @include breakpoint(xxs) {
            font-size: 15px;
            color: white;

          }
        }

      }
    }
  }
}

#rubikModal {
  .modal-dialog {
    border-radius: 0;

    @include breakpoint(xs) {
      width: 100%;
      margin: 0;
      padding: 0 25px;
    }

    .modal-content {
      background-color: transparent;
      box-shadow: none;
      border: none;

      .modal-body {
        padding: 0;
        position: relative;
        height: 500px;

        @include breakpoint(sm) {
          height: 350px;
        }

        @include breakpoint(xs) {
          height: auto;
        }

        .close {
          position: absolute;
          top: -20px;
          right: -15px;
          font-size: 25px;
          color: #fff;
          opacity: 1;
          text-shadow: none;
        }

        iframe {
          width: 100%;

          @include respond-to(768) {
            height: 100%;
          }

          @media (max-width: 450px) {
            height: 200px;
          }
        }
      }
    }
  }
}

#carousel-home {
  border-bottom: 8px solid $blue;
  height: auto;
  margin-top: 102px;

  .carousel-inner>.item {
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .carousel-caption {
    color: #d81d04;
    font-size: 21px;
    position: relative;
    left: 15%;
    bottom: 0;
    text-align: left;
    text-shadow: none;
    text-transform: uppercase;

    h2 {}

    .bird {
      position: absolute;
      bottom: 0;
      right: 0;
    }

    ul {
      list-style: none;

      li {
        margin-bottom: 30px;

        i {
          color: $blue;
          font-size: 28px;
          margin-right: 20px;
        }
      }
    }

    a {}
  }

  .carousel-indicators li,
  .carousel-indicators .active {
    border: 1px solid #ccc;
    border-radius: 0;
    height: 15px;
    margin: 0 3px;
    width: 15px;
    border-radius: 50px;
  }

  .carousel-indicators .active {
    background: #1e2a3d;
  }


}

// Home Products
.lineas-prod {
  background: url(../images/bg-smoke.jpg) no-repeat top center;
  padding: 80px 0;

  .box {
    background: #fff;
    position: relative;
    min-height: 367px;
    overflow: hidden;
    margin: 20px 0;

    h3 {
      font-family: $open;
      font-weight: 300;
      color: #434a54;
      font-size: 33px;
      text-align: right;
      text-transform: uppercase;
      line-height: 23px;
      padding: 30px 20px 0 0;
      margin: 0;

      @include breakpoint(sm) {
        padding: 50px 60px 0 0;
        font-size: 50px;
      }

      @include breakpoint(xxs) {
        padding: 30px 20px 0 0;
        font-size: 33px;
      }

      strong {
        color: #e50012;
        font-size: 17px;

        @include breakpoint(sm) {
          font-size: 20px;
        }

        @include breakpoint(xxs) {
          font-size: 17px;
        }
      }
    }

    p {
      color: #4f5864;
      font-size: 13px;
      font-weight: 300;
      text-align: right;
      position: absolute;
      z-index: 9;
      right: 20px;
      top: 90px;
      // width: 160px;
      width: 54%;

      @include breakpoint(sm) {
        font-size: 16px;
        right: 60px;
        top: 120px;
      }

      @include breakpoint(xxs) {
        font-size: 13px;
        right: 20px;
        top: 120px;
      }
    }

    img {
      position: absolute;
      left: -34px;
      bottom: 0;
      z-index: 1;
      transform: scale(0.7);
    }

    .img-2 {
      transform: scale(0.8);
    }

    .ver-prod-btn {
      background: $blue;
      color: #fff;
      display: block;
      text-transform: uppercase;
      text-align: right;
      position: absolute;
      bottom: 0;
      z-index: 9;
      font-weight: 300;
      padding: 20px;
      width: 100%;
      transition: ease-out .18s;
      -webkit-transition: ease-out .18s;

      &:after {
        font-family: 'ionicons', sans-serif;
        content: "\f3d3";
        padding-left: 0;
        opacity: 0;
        transition: ease-out .18s;
        -webkit-transition: ease-out .18s;
      }

      &:hover {
        background: #d50c00;
        // font-weight: 700;
        transition: ease-out .18s;
        -webkit-transition: ease-out .18s;

        &:after {
          font-family: 'ionicons', sans-serif;
          content: "\f3d3";
          opacity: 1;
          padding-left: 10px;
          transition: ease-out .18s;
          -webkit-transition: ease-out .18s;
        }

      }
    }
  }
}

.prod-destacados {
  padding: 80px 0;

  h2 {
    color: $blue;
    font-family: $open;
    font-weight: 300;
    font-size: 33px;
    line-height: 30px;
    text-transform: uppercase;
    text-align: center;

    strong {
      font-weight: 800;
      display: block;
    }
  }

  .owl-carousel {
    padding: 30px 0;

    .owl-stage {
      display: flex;

      .owl-item {
        display: flex;
        justify-content: center;
      }
    }
  }

  .fx {
    display: flex;
    flex-wrap: wrap;

    .flex {
      display: flex;
      justify-content: center;

      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        min-height: 310px;
        width: 100%;

        h3 {
          color: #434a54;
          font-family: $open;
          font-weight: 300;
          font-size: 18px;
          text-align: center;
        }

        img {
          max-width: 100%;
        }

        p {
          font-size: 13px;
          color: $blue;
          ;
          margin-bottom: 20px;
        }

        .btn-int {
          // visibility: hidden;
        }

        &:hover {

          // border:1px solid #dddee0;
          .btn-int {
            background: #1958ae;
            color: #fff;
            display: block;
            font-size: 15px;
            font-weight: bold;
            padding: 15px 0;
            border-radius: 0;
            visibility: visible;

          }
        }


        a {
          width: 100%;
        }
      }

    }
  }



}


// Block Section Empresa
.valores {
  background: url(../images/empresa.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: left !important;
  padding: 70px 0;
  position: relative;
  color: #fff;

  .row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  h2 {
    font-weight: 700;
    text-transform: uppercase;
    margin: 30px 0;
  }

  .items {
    padding: 0 30px 0 0;

    ul {
      list-style-image: url(../images/item.png);
      padding-left: 2em;
      margin-left: 0;

      li {
        color: #fff;
        font-size: 14px;
        font-weight: 300;
        margin: 0 0 1em 0;
        position: relative;
        line-height: 20px;
        text-align: justify;

        span {
          position: relative;
          top: -6px;
        }
      }
    }
  }

}

//
.bgdark {
  background: rgba(7, 71, 158, 0.7);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

//
.quality {
  padding-top: 40px;



  .col-lg-3 {
    border: 1px solid #ebebeb;
    padding: 20px 30px;
    min-height: 270px;
  }

  span {
    font-weight: 700;
    color: #191b1f;
    text-transform: uppercase;
    text-align: center;
    display: block;
    padding: 10px 0;
  }

  p {
    text-align: justify;
    font-size: 12px;
  }

}

//
.info {
  padding: 85px 10% 0;
  position: relative;

  @include breakpoint(xxs) {
    padding: 55px 10% 0;
  }

  &:before {
    content: "";
    background: #fff;
    height: 2px;
    width: 50px;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  p {
    color: #fff;
    font-family: $open;
    padding: 10px 0;
    margin: 0;
    font-size: 15px;
    line-height: 23px;

    @include breakpoint(xxs) {
      font-size: 13px;
    }

    &:nth-child(1) {
      padding-top: 25px;
    }
  }
}

//
.vme {

  .col-lg-4 {
    padding: 0;
  }

  .bg {
    background-color: #f8f8f8;
  }

  .pint {
    background: url(../images/bg-pint-01.jpg) no-repeat;
    background-position: 0;

    @include breakpoint(xxs) {
      background: none;
    }
  }

  .pint2 {
    background: url(../images/bg-pint-02.jpg) no-repeat;
    background-position: 0;
    @include background-size(cover);

    @include breakpoint(xxs) {
      background: none;
    }
  }

  .box {
    padding: 0 80px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include breakpoint(xxs) {
      padding: 40px;
      top: 120px;
      min-height: auto;
    }

    img {
      align-self: center;
    }


    h1 {
      font-weight: bold;
      color: $blue;
      text-transform: uppercase;
      font-size: 40px;
    }

    p {
      color: $blue;
      text-align: justify;
      font-weight: 300;
    }
  }
}

.wall {
  background: url(../images/bg-wall.jpg) no-repeat center top;
}

// Carousel section Empresa
#carousel-gallery {
  clear: both;
  overflow: hidden;
  margin-top: 50px;

  .col-md-10 {
    padding: 10px;
    border: 2px dotted #427ac6;
  }

  .control {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50px;
    text-align: center;
  }

  .carousel-control.right,
  .carousel-control.left {
    background-image: none;
  }

  .carousel-control {
    top: 50%;
    width: 52px;

    @include breakpoint(xxs) {
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
    }

    span {
      @include breakpoint(xxs) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
      }
    }
  }

  .arrow {
    background: url(../images/arrows.png) no-repeat;
    height: 53px;
    display: block;
    width: 52px;
  }

  .ar-left {
    background-position: center top;
  }

  .ar-right {
    background-position: center bottom;
  }

}

// VISTA NUEVA EMPRESA

#header-empresa {
  background-image: url(../images/empresa/bg-header.jpg);
  background-size: cover;
  border-bottom: solid 4px #d61300;

  .container {
    padding: 120px 0px;

    h1 {
      font-family: $open;
      color: white;
      font-size: 60px;
      font-weight: bold;
      position: relative;

      &:after {
        content: '';
        height: 2px;
        width: 43px;
        left: 0;
        position: absolute;
        background-color: white;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: -26px;
      }
    }

    p {
      font-size: 15px;
      color: #fff;
      padding-left: 10%;
      padding-right: 10%;
    }
  }
}

#items {
  border-bottom: solid 4px $blue;

  .container-fluid {
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    align-items: center;

    @include breakpoint(md) {
      display: inherit;
    }

    .label-owl {
      position: absolute;
      z-index: 999;
      right: 45px;
      bottom: 0;
      text-align: right;
      background-color: #fff;
      padding: 7px 10px 5px 50px;
      border-bottom: solid 4px #df0942;

      @include breakpoint(xs) {
        right: 13%;
      }

      h3 {
        font-family: $open;
        font-size: 18px;
        color: $blue;
        margin: 0px;

        @include breakpoint(xs) {
          font-size: 14px;
        }
      }
    }

    .owl-col {
      padding-left: 0px;
      padding-right: 0px;

      .owl-carousel {
        .owl-controls {
          position: absolute;
          right: 7px;
          bottom: 5px;

          .owl-nav {
            .owl-prev {
              background-color: white;
              padding: 5px 10px;
              margin-bottom: 5px;
              color: #636363;
            }

            .owl-next {
              background-color: white;
              padding: 5px 10px;
              color: #636363;
            }
          }
        }
      }
    }

    .text-wrap {
      @include breakpoint(md) {
        padding: 30px;
      }

      span {
        font-weight: 700;
        color: #191b1f;
        text-transform: uppercase;
        text-align: center;
        display: block;
        padding: 10px 0;
      }

      p {
        text-align: justify;
        font-size: 12px;
        margin-bottom: 20px;
      }

      .row .col-sm-6 {
        padding-left: 30px;

        @include breakpoint(md) {
          padding-left: 15px;
        }
      }
    }
  }
}

#politics {
  .container-fluid {
    padding-left: 0px;
    padding-right: 0px;
    background-color: #ebebeb;
    display: flex;
    align-items: center;

    @include breakpoint(sm) {
      flex-direction: column;
    }

    .text-wrap {
      padding: 0px 30px;

      @include breakpoint(sm) {
        padding: 25px 30px;
      }

      h3 {
        font-family: $open;
        font-size: 41px;
        color: $blue;
        font-weight: bold;
        margin-bottom: 30px;
        margin-top: 0px;
      }

      p {
        font-size: 14px;
        color: #727272;
        line-height: 25px;
        margin-bottom: 0px;

        span {
          color: #3a74c3;
          font-weight: bold;
          font-size: 17px;
        }
      }
    }

    .image-wrap {
      background: url(../images/empresa/politics-2.png) no-repeat center center;
      background-size: cover;
      min-height: 600px;

      @include breakpoint(sm) {
        width: 100%;
        min-height: 400px;
      }
    }
  }
}

.vision-mision {
  .container-fluid {
    background-color: #f8f8f8;

    .row {
      display: flex;
      align-items: center;

      @include breakpoint(sm) {
        flex-direction: column;
      }

      .paint-wrap {
        padding-left: 0px;
        padding-right: 0px;
      }

      .text-wrap {
        padding-left: 0px;
        padding-right: 0px;

        @include breakpoint(md) {
          padding: 30px 25px 0px 25px;
        }

        .box {
          padding-left: 0px;
          padding-right: 0px;

          @include breakpoint(xxs) {
            padding: 40px;
            min-height: auto;
          }

          h1 {
            font-weight: bold;
            color: $blue;
            text-transform: uppercase;
            font-size: 40px;
          }

          p {
            color: #656565;
            text-align: justify;
            font-weight: 300;
          }
        }
      }

      .image-wrap {
        background-color: #f8f8f8;
      }
    }
  }
}

// Hay Color Home

section.hc-home-slider {
  .container-fluid {
    padding: 0px;

    .oc-hero-slider {
      .item {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        .text-wrapper {
          padding: 150px 0px;

          @include breakpoint(sm) {
            padding: 100px 0px;
          }

          h2 {
            font-family: 'Roboto', sans-serif;
            font-size: 60px;
            color: white;
            font-weight: bold;
          }

          .btn-haycolor {
            margin: 20px 0px;
            background-color: $blue;
            color: white;
            font-weight: 600;
            padding: 10px 25px;
            transition: all .2s ease-in-out;
            position: relative;

            &:hover {
              background: white;
              color: $blue;
              transition: all .2s ease-in-out;
            }
          }
        }
      }
    }
  }
}

section.hc-tendencias {
  .row {
    margin-bottom: 70px;
    margin-top: 50px;

    @include breakpoint(sm) {
      margin-bottom: 40px;
    }

    h3 {
      font-size: 40px;
      color: #707070;
      font-family: 'Roboto', sans-serif;
      font-weight: 700;

      a {
        color: inherit;
      }
    }

    p {
      font-family: 'Roboto', sans-serif;
      margin: 20px 0px;
      font-size: 15px;
      line-height: 26px;
    }

    .btn-haycolor {
      background-color: $blue;
      color: white;
      font-weight: 600;
      padding: 10px 25px;
      transition: all .2s ease-in-out;
      position: relative;

      &:hover {
        transition: all .2s ease-in-out;
        background: #0f58bb;
      }
    }

    .color-wrapper {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      padding-top: 8%;

      @include breakpoint(sm) {
        justify-content: flex-start;
      }

      .color {
        width: 80px;
        height: 80px;
        transform-origin: center;
        background-color: #ff6868;
        transition: all .3s ease-out;
        position: relative;
        -webkit-box-shadow: 5px 5px 26px 0px rgba(0, 0, 0, 0.16);
        -moz-box-shadow: 5px 5px 26px 0px rgba(0, 0, 0, 0.16);
        box-shadow: 5px 5px 26px 0px rgba(0, 0, 0, 0.16);

        &:hover {
          transform: scale(1.2);
          z-index: 9;
        }

        &:hover i {
          opacity: 1;
          transition: all .35s ease-out;
        }

        .check {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
          text-align: center;
          color: white;
          opacity: 0;
          transition: all .3s ease-out;
        }
      }
    }
  }
}

section.hc-espacios {
  padding: 50px 0px;

  @include breakpoint(sm) {
    padding: 5px 0px;

    .owl-nav {
      margin-top: 15px;
      display: flex;
      justify-content: center;

      div {
        padding: 0px 15px;
      }
    }
  }

  .col-sm-4 {
    h2 {
      font-size: 40px;
      color: #707070;
      font-family: 'Roboto', sans-serif;
      font-weight: 700;

      a {
        color: inherit;
      }
    }

    .btn-haycolor {
      background-color: $blue;
      color: white;
      font-weight: 600;
      padding: 10px 25px;
      transition: all .2s ease-in-out;
      position: relative;

      &:hover {
        background: #0f58bb;
        transition: all .2s ease-in-out;
      }
    }
  }

  .col-sm-8 {
    @include breakpoint(sm) {
      padding-top: 40px;
    }

    .oc-espacios-slider {
      padding: 20px 0px;

      .owl-stage-outer {
        padding: 20px 0px;

        .item {
          background-color: #f1f1f1;
          padding-bottom: 10px;
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);

          .color-note-wrapper {
            display: flex;
            justify-content: flex-start;
            padding: 5px;
            flex-wrap: wrap;
            height: 45px;
            margin-bottom: 5px;
            overflow: hidden;

            .color-note {
              div {
                width: 25px;
                height: 25px;
                margin: 10px 3px 0px 3px;
              }
            }

            img {
              width: 30px;
              height: 30px;
              margin: 10px 3px 0px 3px;
            }
          }
        }
      }
    }


    .note-name {
      font-size: 14px;
      font-weight: 600;
      color: #7F7F7F;
      margin: 0px 10px;
      height: 41px;
      overflow: hidden;
    }

    /*.owl-nav {
			margin-top: 25px;
			display: flex;
			justify-content: center;
			div {
				padding: 5px;
				i {
					font-size: 20px;
					color: #D1D1D1;
					&:hover {
						color: $blue;
					}
				}
			}
		}*/
    .owl-prev {
      width: 15px;
      position: absolute;
      top: 50%;
      margin-left: -35px;
      display: block !important;
      border: 0px solid black;

      @include breakpoint(sm) {
        position: inherit;
        top: inherit;
        margin-left: 0px;
      }
    }

    .owl-next {
      width: 15px;
      position: absolute;
      top: 50%;
      right: -35px;
      display: block !important;
      border: 0px solid black;

      @include breakpoint(sm) {
        position: inherit;
        top: inherit;
        margin-left: 0px;
      }
    }

    .owl-prev i,
    .owl-next i {
      color: #ccc;
      font-size: 18px;

      &:hover {
        color: $blue;
      }
    }
  }
}

section.hc-destacados {
  padding: 50px 0px;

  @include breakpoint(sm) {
    padding: 30px 0px;
  }

  .col-sm-4 {
    h2 {
      font-size: 40px;
      color: #707070;
      font-family: 'Roboto', sans-serif;
      font-weight: 700;

      a {
        color: inherit;
      }
    }
  }

  .item-wrapper {
    @include breakpoint(sm) {
      padding-top: 40px;
    }

    .item {
      background: #f1f1f1;
      margin-bottom: 20px;

      img {
        height: 115px;
        width: 100%;
      }

      .box {
        width: 100%;
        height: 115px;
      }

      .text-wrapper {
        padding: 15px 10px;

        p {
          font-size: 14px;
          font-weight: bold;
          color: #7F7F7F;
          margin-bottom: -2px;
        }

        span {
          font-size: 12px;
          font-family: 'Roboto', sans-serif;
          color: #B1B1B1;
        }
      }
    }
  }
}

section.hc-moretendencys {
  padding: 0;
  margin-top: 30px;
  background: url(../images/bg-parralax-haycolor.jpg);
  position: relative;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  img {
    width: 100%;
  }

  .overlay {
    @include breakpoint(sm) {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  .col-xs-12 {
    padding: 0px;

    .cta-wrapper {
      // position: absolute;
      // top: 50%;
      // transform: translateY(-50%);
      // right: 8%;
      padding: 55px 15%;
      position: relative;
      float: right;

      @include breakpoint(sm) {
        padding: 50px;
        float: none;
        text-align: center;
      }

      h2 {
        font-size: 40px;
        color: #707070;
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        margin-top: 0px;

        @include breakpoint(sm) {
          color: white;
          font-size: 35px;
        }
      }

      .btn-vermas {
        background: none;
        color: #707070;
        font-weight: 600;
        margin-top: 15px;
        padding: 12px 25px;
        border: 1px solid #707070;
        transition: all .2s ease-in-out;
        position: relative;

        @include breakpoint(sm) {
          margin-top: 0px;
          color: white;
          border-color: white;
        }

        &:hover {
          background-color: #707070;
          color: white;
          transition: all .2s ease-in-out;

          @include breakpoint(sm) {
            background-color: transparent;
          }
        }
      }
    }
  }
}


// Hay Color Notes Page

section.hc-note-hero {
  .container {
    .title-wrapper {
      padding: 30px 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      h1 {
        font-family: 'Roboto', sans-serif;
        color: $blue;
        font-size: 30px;
        font-weight: bold;
        display: -webkit-inline-box;
        margin: 0px;
      }

      .share {
        color: #707070;
        font-size: 14px;
        float: right;
        font-weight: 600;

        a {
          padding: 15px 19px;
          color: white;
          border-radius: 60px;
          background-color: #3a74c3;
          margin-left: 5px;
        }
      }

      .share-wraper {
        display: flex;
        align-items: center;

        p {
          margin: 0px;
          text-transform: uppercase;
          font-weight: 600;
        }

        .share-facebook {
          background-color: #3a74c3;
          padding: 8px 13px;
          border-radius: 25px;
          color: white;
          font-size: 20px;
          line-height: 0;
          margin-left: 13px;
        }
      }
    }

    .description-wrapper {
      font-family: 'Roboto', sans-serif;
      color: #707070 !important;
      font-size: 16px;
      line-height: 33px;
      padding: 20px 0px;

      p {
        font-weight: 400;
        font-style: normal;
        color: #707070 !important;
        font-size: 16px;
        line-height: 33px;
        letter-spacing: -.003em;
      }
    }

    .owl-theme .owl-dots {
      position: absolute;
      bottom: 0;
      left: 5px;
      -webkit-tap-highlight-color: transparent;
    }

    .owl-theme .owl-dots .owl-dot {
      display: inline-block;
      zoom: 1;
      *display: inline;
    }

    .owl-theme .owl-dots .owl-dot span {
      width: 15px;
      height: 15px;
      margin: 5px 7px;
      background: #ececec;
      display: block;
      -webkit-backface-visibility: visible;
      transition: opacity 200ms ease;
      border-radius: 30px;
    }

    .owl-theme .owl-dots .owl-dot.active span,
    .owl-theme .owl-dots .owl-dot:hover span {
      background: #c3c3c3;
    }
  }
}

.centered {
  float: none;
  margin: 0 auto;
}

section.hc-note-relacionados {
  padding: 40px 0px;

  h2 {
    font-size: 40px;
    color: #707070;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    margin-top: 35px;
  }

  .item-wrapper {
    .item {
      background: #f1f1f1;
      margin-bottom: 20px;

      img {
        height: 115px;
        width: 100%;
      }

      .box {
        width: 100%;
        height: 115px;
      }

      .text-wrapper {
        padding: 15px 10px;

        p {
          font-size: 14px;
          font-weight: bold;
          color: #7F7F7F;
          margin-bottom: -2px;
        }

        span {
          font-size: 12px;
          font-family: 'Roboto', sans-serif;
          color: #B1B1B1;
        }
      }
    }
  }
}

section.hc-pintureria-cercana {
  padding: 40px 0px;

  .container {
    background: url(../images/map-background.jpg) no-repeat center center;
    background-size: cover;

    .info-wrapper {
      display: flex;
      padding: 20px;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      h2 {
        font-size: 30px;
        font-family: 'Roboto', sans-serif;
        color: $blue;
        font-weight: bold;
      }

      .btn-haycolor {
        background-color: $blue;
        color: white;
        font-weight: 600;
        padding: 10px 25px;
        transition: all .2s ease-in-out;

        &:hover {
          background: #0f58bb;
          transition: all .2s ease-in-out;
        }
      }
    }
  }
}

section.hc-products-relacionados {
  h2 {
    font-size: 40px;
    font-family: 'Roboto', sans-serif;
    color: #7F7F7F;
    font-weight: bold;
    margin: 30px 0px;
  }

  .product-wrapper {
    margin-bottom: 40px;

    .hc-product-container {
      transition: all .2s ease-in-out;

      img {
        padding: 20px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        border: solid 7px #f1f1f1;
      }

      .hc-product-title {
        background-color: #f1f1f1;
        height: 60px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        i {
          color: $blue;
          opacity: 0;
        }
      }

      &:hover {
        transform: scale(1.01);
        transition: all .2s ease-in-out;
        -webkit-box-shadow: 0 3px 10px 0 rgba(39, 46, 87, .12);
        box-shadow: 0 3px 10px 0 rgba(39, 46, 87, .12);
        border-bottom: solid 3px $blue;

        i {
          opacity: 1;
        }
      }
    }
  }

}

section.hc-puede-gustar {
  padding: 30px 0px 60px 0px;

  h2 {
    font-size: 40px;
    font-family: 'Roboto', sans-serif;
    color: #7F7F7F;
    font-weight: bold;
    margin: 30px 0px;
  }

  .item {
    background-color: #f1f1f1;
    padding-bottom: 10px;
    margin-bottom: 20px;

    img {
      width: 100%;
    }

    .color-note-wrapper {
      display: flex;
      justify-content: flex-start;
      padding: 5px;
      flex-wrap: wrap;
      height: 45px;
      margin-bottom: 5px;
      overflow: hidden;

      .color-note {
        div {
          width: 25px;
          height: 25px;
          margin: 10px 3px 0px 3px;
        }
      }

      img {
        width: 30px;
        height: 30px;
        margin: 10px 3px 0px 3px;
      }
    }
  }

  .note-name {
    font-size: 14px;
    font-weight: 600;
    color: #7F7F7F;
    margin: 0px 10px;
    height: 41px;
    overflow: hidden;
  }

  .btn-haycolor {
    margin: 20px 0px;
    background-color: $blue;
    color: white;
    font-weight: 600;
    padding: 10px 25px;
    transition: all .2s ease-in-out;
    position: relative;

    &:hover {
      background: #0f58bb;
      transition: all .2s ease-in-out;
    }
  }
}

// Hay color tendency page search

section.hc-tendency-top {
  font-family: 'Roboto', sans-serif;

  .title {
    padding: 30px 0px;

    span {
      color: #B2B9BD;
      font-size: 15px;
      font-weight: bold;
      text-transform: uppercase;
    }

    h1 {
      color: #7F7F7F;
      font-weight: bold;
      margin: 15px 0px 20px 0px;
      font-size: 60px;
    }
  }

  select {
    width: 300px;
  }

  .btn-search {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.428571429;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .btn-haycolor {
    margin-left: 30px;
    background-color: $blue;
    color: white;
    font-weight: 600;
    padding: 10px 25px;
    transition: all .2s ease-in-out;

    @include breakpoint(sm) {
      margin-left: 0px;
      margin-top: 30px;
    }

    &:hover {
      background: #0f58bb;
      transition: all .2s ease-in-out;
    }
  }

  .row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    form {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      @include breakpoint(sm) {
        text-align: center;
      }

      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: #fff;
        border: none;
        color: #707070;
        padding: 10px 50px 10px 20px;
        border-bottom: solid 2px #3a74c3;
        font-size: 15px;
        background-image:
          linear-gradient(45deg, transparent 50%, $blue 50%),
          linear-gradient(135deg, $blue 50%, transparent 50%);
        background-position:
          calc(100% - 20px) calc(1em + 2px),
          calc(100% - 15px) calc(1em + 2px),
          calc(100% - 2.5em) 0.5em;
        background-size:
          5px 5px,
          5px 5px,
          1px 1.5em;
        background-repeat: no-repeat;

        &:focus {
          background-image:
            linear-gradient(45deg, $blue 50%, transparent 50%),
            linear-gradient(135deg, transparent 50%, $blue 50%);
          background-position:
            calc(100% - 15px) 1em,
            calc(100% - 20px) 1em,
            calc(100% - 2.5em) 0.5em;
          background-size:
            5px 5px,
            5px 5px,
            1px 1.5em;
          background-repeat: no-repeat;
          outline: 0;
        }

        @include breakpoint(sm) {
          width: 100%;
        }
      }
    }

    .palette-wrapper {
      @include breakpoint(xs) {
        padding-top: 30px;
      }

      .color-wrapper {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;

        @include breakpoint(sm) {
          justify-content: flex-start;
        }

        img {
          width: 80px;
          height: 80px;
        }

        .color {
          width: 80px;
          height: 80px;
          cursor: pointer;
          transform-origin: center;
          background-color: white;
          transition: all .3s ease-out;
          position: relative;
          -webkit-box-shadow: 5px 5px 26px 0px rgba(0, 0, 0, 0.16);
          -moz-box-shadow: 5px 5px 26px 0px rgba(0, 0, 0, 0.16);
          box-shadow: 5px 5px 26px 0px rgba(0, 0, 0, 0.16);

          &:hover {
            transform: scale(1.2);
            z-index: 9;
          }

          &:hover i {
            opacity: 1;
            transition: all .35s ease-out;
          }

          .check {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            text-align: center;
            color: white;
            opacity: 0;
            transition: all .3s ease-out;
          }
        }
      }

      .palette-name {
        margin-top: 5%;
        margin-right: 5%;
        font-size: 15px;
      }

      .palette-name-2 {
        padding-left: 2%;
        font-size: 15px;
      }
    }
  }
}

section.hc-tendency-notes {
  padding: 80px 0px;

  .item {
    background-color: #f1f1f1;
    padding-bottom: 10px;
    margin-bottom: 30px;

    img {
      width: 100%;
    }

    .color-note-wrapper {
      display: flex;
      justify-content: flex-start;
      padding: 5px;
      flex-wrap: wrap;
      height: 45px;
      margin-bottom: 5px;
      overflow: hidden;

      .color-note {
        div {
          width: 25px;
          height: 25px;
          margin: 10px 3px 0px 3px;
        }
      }

      img {
        width: 30px;
        height: 30px;
        margin: 10px 3px 0px 3px;
      }
    }
  }

  .note-name {
    font-size: 14px;
    font-weight: 600;
    color: #7F7F7F;
    margin: 0px 10px;
    height: 41px;
    overflow: hidden;
  }
}

// Hay color espacios page search

.scale-color {
  transform: scale(1.2);
  z-index: 9;
}

.fa-color {
  opacity: 1 !important;
}

// Blocks section Home
.wrp-home {
  background: url(../images/bg1.jpg) no-repeat center center;
  background-size: cover;
  border-bottom: 1px solid #FFF;
  padding: 50px 0;

  .box {
    border-top: 1px solid #FFF;
    border-left: 1px solid #FFF;
    box-shadow: -1px -1px 1px rgba(0, 0, 0, .1) inset;
    color: #FFF;
    margin-bottom: 20px;



    h1 {
      text-transform: uppercase;
    }
  }

  .cont1,
  .cont2 {
    overflow: hidden;
    position: relative;
  }

  .cont2 {
    .cont2-child2 {
      @include breakpoint(xs) {
        position: relative;
      }
    }
  }

  .lineas {

    .carousel-lineas,
    .item {
      height: 100%;
    }

    .carousel-inner {
      height: 100%;
    }
  }


  .box1 {
    background: url(../images/box1.jpg) no-repeat center center;
    background-size: cover;
    min-height: 500px;
    padding-top: 40px;

    @include breakpoint(xs) {
      text-align: center;
    }

    h1 {
      font-size: 34px;
      margin-bottom: 25px;
      margin-top: 0;

      span {
        font-size: 18px;
      }
    }

    a {
      background: #20c993;

      &:hover {
        color: #20c993;
      }
    }
  }

  .col-md-8 {
    position: absolute;
    height: 100%;

    .c1 {
      background: url(../images/box2.jpg) no-repeat center center;
      background-size: cover;
    }

    .c2 {
      background: url(../images/c2.jpg) no-repeat center center;
      background-size: cover;
    }

    .c3 {
      background: url(../images/c3.jpg) no-repeat center center;
      background-size: cover;
    }

    .box {

      height: 94%;
      position: relative;
      padding: 85px 0 0 60px;

      h1,
      p {
        clear: both;
        font-size: 48px;
        margin-bottom: 40px;
        padding: 0;

        span {
          font-size: 30px;
        }
      }

      h1 {
        font-weight: 700;
        line-height: .8;
      }

      p {
        color: #fff;
        font-size: 16px;
      }
    }
  }

  .box3 {
    background: url(../images/box3.jpg) no-repeat center center;
    background-size: cover;
    padding: 50px 30px 35px 50px;

    @include breakpoint(xxs) {
      padding: 30px;
    }

    h1 {
      font-weight: 700;
      font-size: 47px;

      @include breakpoint(xxs) {
        margin: 0 0 20px 0;
      }

      span {
        font-size: 34px;
      }
    }

    p {
      color: #fff;
      font-size: 16px;
      margin-bottom: 20px;
    }

    a {
      background: #ec6333;
    }
  }

  .box4 {
    background: url(../images/box4.jpg) no-repeat;
    background-size: cover;
    padding: 30px 40px;

    @include breakpoint(xs) {
      padding: 30px;
    }

    h1 {
      font-weight: 700;
      font-size: 47px;
      color: #2971c4;

      @include breakpoint(xs) {
        font-size: 40px;
        margin: 0 0 20px 0;
      }

      span {
        font-size: 34px;

        @include breakpoint(xs) {
          font-size: 30px;
        }
      }
    }

    a.btn-custom {
      margin-top: 20px;
      background-color: $blue;

      @include breakpoint (xxs) {
        font-size: 12px;
        padding: 8px 40px;
      }
    }
  }

  .col-md-5 {
    position: absolute;
    height: 100%;

    .box {
      height: 94.5%;
      position: relative;
      @include breakpoint (xs) {
        height: 282px;
        background-image: url(../images/gallery/001.jpg)
      }

    }
  }

}

// Section List products
section#sec-products {
  background: #fafafa;
  font-family: 'Roboto', sans-serif;

  // aside.affix-top{
  //   position: absolute;
  // }

  .sidebar-productos-open {
    left: 0 !important;
    transition: ease-out .18s;
    -webkit-transition: ease-out .18s;
    -moz-transition: ease-out .18s;
    -ms-transition: ease-out .18s;
    -o-transition: ease-out .18s;
  }

  aside {
    padding-right: 0;

    @include breakpoint(sm) {
      position: fixed;
      top: 0;
      left: -300px;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      background: white;
      z-index: 1062;
      max-width: 300px;
      padding: 30px 20px 10px;
      border-right: 1px solid #e4eaf3 !important;
      transition: ease-out .18s;
      -webkit-transition: ease-out .18s;
      -moz-transition: ease-out .18s;
      -ms-transition: ease-out .18s;
      -o-transition: ease-out .18s;
    }

    #close-sidebar-productos {
      font-size: 30px;
      position: absolute;
      right: 15px;
      top: 0;
      display: none;
      cursor: pointer;

      @include breakpoint(sm) {
        display: block;
      }
    }

    .menus {
      display: block !important;
      border-right: 1px solid #e4eaf3;

      @include breakpoint(sm) {
        border-right: 0;
      }
    }

    a.btn {
      margin-top: 30px;
      width: 100%;

      i {
        margin-right: 5px;
      }

    }

    a.lineasHide {
      border-radius: 3px;
      color: $blue;
      line-height: 35px;
      text-align: center;
      text-transform: uppercase;
    }

    h3 {
      border-bottom: 2px solid #d0d0d0;
      color: #333;
      cursor: pointer;
      font-size: 16px;
      margin: 0;
      padding: 20px;

      span {
        float: right;
        font-size: 24px;
      }

      &.active {
        color: $blue;
        border-bottom-color: $blue;

        span {
          &:before {
            content: "\f3d8";
          }
        }
      }
    }

    ul {
      border-bottom: 2px solid #d0d0d0;
      display: none;
      list-style: none;
      margin: 0;
      padding: 0;
      height: 300px;
      overflow-x: hidden;
      overflow-y: scroll;

      li {
        a {
          color: #333;
          display: block;
          padding: 15px 0 15px 40px;

          &:hover,
          &:active,
          &.active {
            background: #e8e8e8;
            color: $blue;
          }

        }

        select {
          margin: 20px 30px 25px 40px;
          max-width: 100%;
          width: auto;
        }
      }
    }
  }

  .col-md-9 {
    padding-left: 30px;

    // .fx{
    // 	display:flex;
    // 	flex-wrap:wrap;
    //
    // 		.flex{
    // 			display:flex;
    // 			justify-content: center;
    //
    // 				.content{
    // 						display: flex;
    // 						flex-direction: column;
    // 						justify-content: space-between;
    // 						align-items: center;
    // 						width: 100%;
    // 						a{
    // 							width: 100%;
    // 						}
    // 				}
    //
    // 		}
    //
    //
    // }

    .filters {
      margin-bottom: 40px;
      text-align: right;
      position: relative;
      padding-left: 50px;

      @media (max-width: 449px) {
        padding-left: 0;
      }

      #sidebar-productos {
        font-size: 24px;
        display: none;
        cursor: pointer;

        @include respond-to(450) {
          position: absolute;
          left: 15px;
          top: 50%;
          @include centerY(-50%);
        }

        @include breakpoint(sm) {
          display: block;
        }

        @media (max-width: 449px) {
          text-align: left;
          padding-left: 15px;
          padding-bottom: 10px;
          margin-bottom: 10px;
          border-bottom: 1px solid #efefef;
        }

        span {
          font-style: normal;
        }
      }

      .custom-padding {
        padding-left: 5px;
        padding-right: 5px;
      }

      .btn-group {
        @include breakpoint(md) {
          margin-right: 20px;
        }
      }

      .btn {
        background: transparent;
        border-bottom: 2px solid #1958ae;
        border-radius: 0;
        padding: 15px 12px;
        text-align: left;
        text-transform: uppercase;
        width: 150px;

        @include breakpoint(xxs) {
          font-size: 12px;
        }

        .fa {
          float: right;
          margin-top: 3px;
        }
      }

      .btn-group.open .dropdown-toggle,
      .btn.active,
      .btn:active {
        background: #FFF;
        border-bottom-color: #FFF;
        box-shadow: 3px 3px 12px rgba(0, 0, 0, .175);
      }

      .dropdown-menu {
        border: none;
        border-radius: 0;
        font-size: 12px;
        margin: 0;
        min-width: 150px;
        width: 150px;

        @include breakpoint(sm) {
          width: 100% !important;
          min-width: inherit !important;
        }

        >li>a {
          color: #7a7a7a;
          padding: 10px;
        }

        >li>a:focus,
        >li>a:hover {
          background: #eaeaea;
          color: #1958ae;
        }
      }

      a.bt {
        background: #1958ae;
        border-radius: 2px;
        color: #FFF;
        display: inline-block;
        float: right;
        font-size: 14px;
        line-height: 40px;
        padding: 0 70px;
        text-transform: uppercase;
      }
    }

    // .content {
    // 	background: #FFF;
    // 	border: 1px solid #d9dadc;
    // 	margin-bottom: 30px;
    // 	padding: 10px 0px 0 0px;
    // 	text-align: center;
    //
    // 	img {
    // 		max-width: 100%;
    // 	}
    // 	h3 {
    // 		color: #434a54;
    // 		font-size: 18px;
    // 		margin: 0 0 15px 0;
    // 	}
    // 	p {
    // 		font-size: 13px;
    // 		color: #1773b6;
    // 		margin-bottom: 20px;
    // 	}
    // 	.btn-int {
    // 		border-top: 1px solid #d8dadb;
    // 		color: #383838;
    // 		display: block;
    // 		font-size: 15px;
    // 		font-weight: bold;
    // 		padding: 15px 0;
    // 		border-radius: 0;
    //
    // 		&:hover{
    // 			background:#1958ae;
    // 			color:#fff;
    // 		}
    //
    // 	}
    // }
  }


  .tit {
    color: $blue;
    font-size: 18px;
    margin: 0 0 30px;
    text-align: center;
    text-transform: uppercase;
  }


  .detalle-buttons-set {
    background: #FFF;
    border: 1px solid #e6e6e6;
    margin-bottom: 15px;
    overflow: hidden;
    padding: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include breakpoint(xs) {
      flex-direction: column;
    }

    .text {
      @include breakpoint(xs) {
        margin-bottom: 15px;
        text-align: center;
      }

      p {
        margin: 0;
        color: $blue;
        font-size: 14px;
        letter-spacing: 0.5px;
        font-weight: 600;
      }
    }

    .buttons {
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 400px) {
        flex-direction: column;
      }

      .button {
        color: $blue;
        background-color: #fff;
        border: 1px solid #d8dadb;
        padding: 8px 15px;
        display: inline-block;
        letter-spacing: 0.5px;
        font-weight: 500;
        font-size: 12px;

        @media (max-width: 400px) {
          width: 100%;
          text-align: center;
        }

        i {
          color: #d8dadb;
          margin-right: 5px;
        }

        &:hover {
          background-color: $blue;
          border-color: $blue;
          color: white;
        }

        &:first-child {
          margin-right: 10px;

          @media (max-width: 400px) {
            margin-right: 0;
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .detalles {
    margin-bottom: 40px;

    .row {
      margin: 0;
    }

    .col-md-9 {
      border: 1px solid #e6e6e6;
      background: #FFF;
      padding: 30px 80px;

      a.btn {
        position: absolute;
        right: 30px;
      }

      h2 {
        color: #1958ae;
        font-size: 14px;
        font-weight: 500;
        margin: 0 0 30px;
        text-decoration: underline;
        text-transform: uppercase;
      }

      p {
        color: #000;
        font-size: 12px;
        font-weight: 300;
        line-height: 1.5;
      }

    }

    .col-md-3 {
      margin-right: -1px;
      padding-right: 0;
      padding-left: 0;
      position: relative;
      z-index: 3;
    }

    .nav-tabs {
      border-bottom: none;

      >li {
        float: none;

        >a {
          background: #f4f4f4;
          border: 1px solid #e6e6e6;
          border-radius: 0;
          color: #017eba;
          font-size: 14px;
          margin-bottom: 2px;
          padding: 20px 15px;
          text-transform: uppercase;
          width: 100%;
        }
      }

      >li.active>a,
      >li.active>a:focus,
      >li.active>a:hover {
        background: #FFF;
        border: 1px solid #e6e6e6;
        border-right-color: transparent;
      }
    }
  }

  .texto {
    background: #FFF;
    border: 1px solid #e6e6e6;
    margin-bottom: 15px;
    overflow: hidden;
    padding: 35px 30px 25px;

    #imageProducto {
      @include breakpoint(xs) {
        margin: auto;
      }
    }

    h1 {
      color: #1958ae;
      font-size: 18px;
      margin: 0 0 35px;
      text-transform: uppercase;

      @include breakpoint(xs) {
        text-align: center;
      }

      span {
        color: #7b7b7b;
      }
    }

    .actions {
      text-align: right;

      a {
        margin: 5px 0;
      }
    }

    .col-md-8 .row {
      border-bottom: 1px solid #e8eaeb;
      margin-bottom: 15px;
    }

    .col-sm-6 {
      margin-bottom: 20px;

      img {
        float: left;
        margin-right: 15px;
      }

      h4 {
        color: #7b7b7b;
        font-size: 12px;
        border-bottom: 1px solid #1958ae;
        overflow: hidden;
        padding-bottom: 5px;
        margin: 0 0 5px;
      }

      p {
        color: #000;
        font-size: 12px;
        overflow: hidden;
      }

      &:nth-child(2n+1) {
        clear: both;
      }
    }
  }
}

// Section History

#h2-link,
#h3-link,
#h4-link,
#h5-link,
#h6-link,
#h7-link {
  position: absolute;
  width: 100%;
  height: 102px;
  bottom: 0;
  left: 0;
  z-index: -999;
  opacity: 0;
}

section#historia {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  padding: 0;
  position: relative;

  nav {
    position: absolute;
    right: 10%;
    top: 950px;
    z-index: 1;

    ul {
      list-style: none;
      position: relative;
      width: 140px;

      li {
        border: 1px solid #939696;
        border-radius: 50%;
        display: block;
        height: 15px;
        left: 50%;
        margin-bottom: 25px;
        margin-left: -7.5px;
        position: relative;
        width: 15px;
        transition: ease-out .18s;
        -webkit-transition: ease-out .18s;
        -moz-transition: ease-out .18s;
        -ms-transition: ease-out .18s;
        -o-transition: ease-out .18s;

        a {
          padding: 0;
          text-indent: -9999px;

          span {
            border: 1px solid #939696;
            border-radius: 15px;
            color: #939696;
            display: none;
            font-size: 14px;
            line-height: 25px;
            left: 20px;
            text-indent: 0;
            top: -7px;
            padding: 0 12px;
            position: absolute;
            transition: ease-out .1s;
            -webkit-transition: ease-out .1s;
            -moz-transition: ease-out .1s;
            -ms-transition: ease-out .1s;
            -o-transition: ease-out .1s;
          }

          &:hover {
            background: #3a74c3;
            border: 2px solid #FFF;
            border-radius: 50%;
            display: block;
            height: 13px;
            left: 0;
            position: absolute;
            top: 0;
            width: 13px;

            span {
              display: block;
            }
          }

          &:focus {
            background: transparent;
          }
        }

        &:after {
          background: #939696;
          content: "";
          display: block;
          height: 25px;
          left: 50%;
          position: absolute;
          top: 14px;
          width: 1px;
        }

        &.active {
          height: 140px;
          left: 0;
          margin-left: 0;
          width: 140px;
          background: rgba(255, 255, 255, 0.8);
          transition: ease-out .18s;
          -webkit-transition: ease-out .18s;
          -moz-transition: ease-out .18s;
          -ms-transition: ease-out .18s;
          -o-transition: ease-out .18s;

          a {
            color: #939696;
            font-size: 46px;
            line-height: 140px;
            text-align: center;
            text-indent: 0;
            transition: ease-out .1s;
            -webkit-transition: ease-out .1s;
            -moz-transition: ease-out .1s;
            -ms-transition: ease-out .1s;
            -o-transition: ease-out .1s;
          }

          &:hover {
            span {
              display: none;
            }

            a {
              background: none;
              border: none;
              width: 100%;
            }
          }

          &:after {
            top: 139px;
          }

          &:nth-child(7) {
            &:after {
              display: none;
            }

            +li {
              display: none;
            }
          }
        }

        &:hover {}

        &:last-child {
          border: none;
          height: auto;
          margin-left: -9px;
          width: 20px;

          &:after,
          &:before {
            display: none;
          }
        }

        &:first-child {
          display: none;
          border: none;
          margin-left: -9px;
          padding-bottom: 25px;
          width: 20px;

          &:after {
            left: 45%;
            top: 25px;
          }
        }
      }
    }

    &.fixed {
      position: fixed;
      top: 190px;
    }

    &.wh {
      ul .active {
        background: transparent;
      }

      ul li {
        border-color: #FFF;

        a {
          color: #FFF;

          span {
            border-color: #FFF;
            color: #FFF;
          }
        }

        &:after {
          background: #FFF;
        }

      }
    }
  }

  article {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    margin: 0;
    padding-bottom: 150px;
    padding-top: 75px;
    position: relative;

    @include breakpoint(xs) {
      padding-bottom: 50px;
    }

    &#h1 {
      background-image: url(../images/h1.jpg);
      background-position-x: right !important;
      border-bottom: 7px solid #d81d04;
      border-top: 1px solid #4c96fe;
      height: calc(100vh - 164px) !important;
      padding: 240px 0;
      text-align: center;
      min-height: inherit !important;

      @include breakpoint(xs) {
        background-size: inherit;
        padding: 100px 0;
      }

      .content-h1 {
        position: absolute;
        width: 100%;
        text-align: center;
        // top: 50%!important;
        // @include centerY(-50%);
      }

      h1 {
        color: #FFF;
        font-weight: 700;
        margin: 0 0 45px;
        text-transform: uppercase;

        @include breakpoint(xs) {
          font-size: 40px;
          margin: 0 0 15px;
        }

        &:after {
          background: #FFF;
          content: "";
          display: block;
          height: 2px;
          margin: 40px auto 0;
          width: 40px;

          @include breakpoint(xs) {
            margin: 15px auto 0;
          }
        }
      }

      p {
        color: #FFF;
        font-size: 18px;
        margin: 0 0 100px;
        text-align: center;
        width: 100%;

        @include breakpoint(xs) {
          font-size: 15px;
        }
      }
    }

    span.bg {
      color: rgba(0, 0, 0, .2);
      display: block;
      font-size: 150px;
      font-weight: 700;
      line-height: 1;
      margin: 0 auto 80px;
      text-align: right;
      width: 50%;
    }

    h2 {
      color: #3a74c3;
      font-size: 46px;
      font-weight: 700;
      line-height: 1;
      margin: 0 auto 40px;
      text-transform: uppercase;
      width: 50%;
    }

    p {
      color: #8b8f8f;
      font-size: 18px;
      line-height: 1.4;
      margin: 0 auto 25px;
      text-align: justify;
      width: 50%;

      .blue {
        color: #3a74c3;
      }
    }

    &#h2 {
      background-image: url(../images/h2.jpg);
      background-position-x: right !important;
    }

    &#h3 {
      background-image: url(../images/h3.jpg);
      border-top: 1px solid #efefef;
    }

    &#h4 {
      background-image: url(../images/h4.jpg);
      background-position-x: right !important;
    }

    &#h5 {
      background-image: url(../images/h5.jpg);
    }

    &#h6 {
      background-image: url(../images/h6.jpg);
      background-position-x: right !important;
    }

    &#h7 {
      background-image: url(../images/h7.jpg);
      // padding-bottom: 480px;

      h2,
      p {
        color: #FFF;
      }


    }
  }
}

// Section Contact
.sucursales{
	background: #f8f8f8;
	margin-bottom: 0;
}

/*contact us*/

.mapa-oculto {
	display: none;
	width: 100%;
	height: 300px;
	background-color: black;

		#map2 {
			width: 100% !important;
			height: 300px !important;
		}

@media screen and (min-width: 550px) {
		display: none;
	}	
}

.big-map{
	@media screen and (max-width: 550px) {
		display: none;
	}
}
#formulario {
	padding-top:45px;
}

.contat-us-wrap{
	padding: 0;

  .cobertura {
    background: #fff;
    position: absolute;
    z-index: 15;
    left: 10px;
    top: 10px;
    padding: 20px;
    width: 320px;

    h3 {
      letter-spacing: 3px;
      color: $blue;
      font-weight: 700;
      margin-top: 0;
      margin-bottom: 20px;
    }

    p {
      color: #5a5a5a;

      strong {
        color: $blue;
        text-transform: uppercase;
      }

    }


  }

	.map-float {
		width: 300px;
		height: 449px;
		position: absolute;
		top: 250px;
		left: 20%;
		background-color: #1e61bb;

			@media screen and (max-width: 550px) {
				width: 100%;
				height: 449px;
				top: 149px;
				left: 0;
				position: inherit;
			}

			ul{
				li{
							display: flex;
							justify-content: space-between;

							i{
								font-size: 11px; 
							}
				}
			}

		.div-imagen {
			width: 100%;
			height: 100px;
			background-color: #FFF;
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;

			img {
				@media screen and (max-width: 550px) {
					display: none;
				}
			}

			p{

				color: #005fae;
				font-size: 13px;
				padding-top: 10px;
				margin-bottom: 0px;

					@media screen and (max-width: 550px) {
						font-size: 15px;
						text-transform: uppercase;
						color: #ffffff;
					}
			}
			@media screen and (max-width: 550px) {
				background-color: #1e61bb;
				color: #ffffff;
			}
		}

	}

	.map-float-childs {
		display: none;
		width: 300px;
		height: 449px;
		position: absolute;
		top: 250px;
		left: 20%;
		background-color: #1e61bb;
		

		@media screen and (max-width: 550px) {
			width: 100%;
			top: 0;
			left: 0;
			position: relative;
			height: auto;

		}

			.boton-cerrar { 
				position: absolute;
				top: 20px; 
				right: 20px;
				z-index: 50;
				padding: 2px 6px;
				color: #4a8de7;
				background: #FFFFFF;
				border-radius: 50%;
				cursor: pointer;
			}
			.ver-mapa {
				color: white;
				text-align: center;

				&::after {
					content: url(../images/arrrow-bottom.png);
					padding-left: 10px;
				}

				@media screen and (min-width: 550px) {
					display: none; 
				}
			}
	}

	.cordoba{
		background:url(../images/gallery/001.jpg) no-repeat;
		background-repeat: no-repeat;
		background-size:cover;
		height:450px;
	}
	.moreno{
		background-image:url(../images/tucuman-contact-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 450px;
  }

  .v-mercedes {
    background-image: url(../images/gallery/003.jpg);
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    height: 450px;
  }

  .bs-as {
    background-image: url(../images/gallery/bs-as.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 450px;
  }

  .planta-rosario {
    background: url(../images/gallery/rosario.jpg) no-repeat;
    background-size: cover;
    height: 450px;
  }

  .planta-mendoza {
    background: url(../images/gallery/mendoza.jpg) no-repeat;
    background-size: cover;
    height: 450px;
  }

  .montevideo-uy {
    background: url(../images/gallery/montevideo-uy.jpg) no-repeat;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 450px;
  }

  .block-title {
    background: 0 0;
    border: none;
    width: 100%;
    display: inline-block;
    position: relative;
    margin-bottom: 25px;
    font-size: 18px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
  }

  .contact-map {
    padding: 0;
  }

	.contenido-card-contacto {
		padding: 15px 5px 37px 37px;
		display: flow-root;

			strong {
				padding-top: 37px;
				font-size: 20px;
				font-weight: bold;
				color: #ffffff;
			}
			small{
				color: #FFF;
				font-size: 15px;
				font-weight: bold;
				margin-bottom: 38px;
			}
					ul{
						color: #ffffff;
						font-size: 14px;
						line-height: 27px;
						text-indent: -37px;
						list-style-type: none;
						padding-top: 15px;

							a{
								color: #ffffff;
							}
					}
	}



	#contactForm{
		.form-list{
			padding-bottom: 50px;
      .add-icon-select{
        position: relative;

        &:before {
          content: "\f3d0";
          font-family: 'Ionicons';
          position: absolute;
          right: 5px;
          top: 50%;
          @include centerY(-50%);
          z-index: -1;
        }
      }

      .add-icon-select.is-focused {
        &:before {
          content: "\f3d8";
        }
      }

      .row {
        .row {
          margin: 0;
        }
      }
    }

    .page-title {
      color: $blue;
      text-align: center;

      h1 {
        border-bottom-color: #ea256a;
        text-transform: uppercase;
        font-weight: 700;
        float: none;
        display: inline-block;
      }
    }

    .block-title {
      strong {
        color: $blue;
        font-weight: normal;
      }
    }

    .fieldset {
      padding: 0;
      margin: 0;
      border: none;

      .form-list {

        .btn-group {
          margin-left: 25px;
        }
      }
    }

    .buttons-set {
      clear: both;
      overflow: hidden;
      margin: 0 0 25px;
      padding: 0;

    }
  }

  .block {
    &.block-contact-address {
      @include make-xs-column(12);
      @include make-sm-column(6);
      @include make-md-column(4);
      @include make-lg-column(3);

      border: 1px solid #d2e3f0;
      border-top: none;
      // padding: 0;
      margin-bottom: 0;
      color: #005fae;
      background: #f8f8f8;
      cursor: pointer;

      a {
        color: #005fae;

        &:hover {
          color: red;
        }
      }

      p {
        margin: 15px 0 35px;

      }

      ul {
        list-style: none;
        margin-bottom: 0;
        padding: 0;

        li {
          margin: 0 0 15px;
          position: relative;
          padding-left: 30px;
          font-size: 13px;

          &:before {
            font-family: FontAwesome;
            left: 7px;
            top: 2px;
            position: absolute;
          }

          &.address:before {
            content: "\f041";
          }

          &.email:before {
            content: "\f003";
          }

          &.phone:before {
            content: "\f095";
          }


        }
      }

      .block-title {
        text-transform: uppercase;
        border-bottom: 1px solid #d2e3f0;
        color: #005fae;
        font-size: 100%;
        padding: 15px 10px;
        padding-bottom: 15px;
        margin-top: 10px;
        margin-bottom: 0;
        position: relative;
        text-align: center;
        height: 85px;
        display: table;
        width: 100%;

        &:before {
          position: absolute;
          content: "";
          bottom: -1px;
          left: 0;
          width: 60px;
          height: 1px;
          background: #ea256a;
          width: 0;
        }

        span {
          display: table-cell;
          vertical-align: middle;
        }

        small {
          font-weight: normal;
        }

        strong {
          float: none;
          border: none;
          padding: 0;
          background: transparent;
          font-weight: normal;
          font-size: 100%;
          color: #005fae;
        }
      }

      .block-boton {
        text-align: center;

        a.boton {
          text-transform: uppercase;
          border: 1px solid #005fae;
          color: #005fae;
          display: inline-block;
          font-size: 12px;
          padding: 6px 20px;
          margin: 15px auto 35px;
          text-align: center;
          white-space: nowrap;
          vertical-align: middle;
          font-weight: bold;
        }
      }

      .block-content {
        padding: 15px;
        height: 230px;

        @include breakpoint(md) {
          height: 180px;
        }
      }

      &.over {
        background: #0a63b0;
        color: #fff;
        border-color: #0a63b0;

        .block-title {
          color: #fff;

          strong {
            color: #fff;
          }
        }

        a {
          color: #fff;
        }

					a.boton {
						text-transform: uppercase;
						background: #fff;
						border-color: #fff;
					}
				}
			}
		}

		&.block-contact-map {

			padding: 13px 0;
			border-bottom: solid 1px #4a8de7;

			&.over {
				color: #fff;

				.block-title {
					color: #fff;

					strong {
						color: #fff;
					}
				}

				a {
					color: #fff;
				}
				.block-boton {
					text-align: center;

					a.boton {
						text-transform: uppercase;
						background: #fff;
						border-color: #fff;
					}
				}
			}

		}
	}

	// .contact-map{
		#map {
		    float: left;
		    height: 600px;
				width: 100%;

				@media screen and (max-width: 550px) {
					width: 100% !important;
					height: 300px !important;
					float: none;
				}
		}
		.map2 {
			float: left;
			height: 600px;
			width: 100%;

			@media screen and (max-width: 550px) {
				width: 100% !important;
				height: 300px !important;
				float: none;
			}
	}
		
// }


.contact-card {
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	color: #FFF;
	cursor: pointer;
	font-weight: 600;
	font-size: 16px;

		ul {
			list-style-type:none;
			margin: 0 10px 0 10px;
			padding: 0 22px;

			li{
				padding: 13px 10px 13px 13px !important;
			}
			
			li:last-child {
				border-bottom: 0 !important;
			}

		}
}



// Footer site
footer {
  background: #e8e8e9;
  border-bottom: 0px solid #ddddde;
  border-top: 5px solid #e1e1e2;
  color: #4f4f50;
  padding: 80px 0 65px;
  font-family: $montserrat;

  .logo-footer {
    @include breakpoint(sm) {
      margin-bottom: 25px;
    }

    @include breakpoint(xs) {
      margin-bottom: 25px;
      max-width: 150px;
    }
  }

  .logo-cert-foot {
    @include breakpoint(xs) {
      margin-bottom: 30px;
    }
  }

  .calidad {
    text-transform: uppercase;
    font-size: 10px;
    text-align: center;
    position: relative;
    margin: 25px 0 20px;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      border-top: 1px solid #fff;
      border-bottom: 1px solid #ccc;
      width: 20px;
    }

    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      border-top: 1px solid #fff;
      border-bottom: 1px solid #ccc;
      width: 20px;
    }
  }

  ul {
    float: left;
    font-size: 12px;
    list-style: none;
    margin-right: 25px;
    padding: 0 0 0 15px;

    li {
      margin-bottom: 20px;
      text-transform: uppercase;

      a {
        color: #4f4f50;
        text-transform: none;
      }

      &:fisrt-child {
        margin-bottom: 40px;
      }
    }
  }

  input {
    background: url(../images/lupa.gif) no-repeat right center;
    border: none;
    border-bottom: 1px solid #b7b7b8;
    height: 25px;
    margin-bottom: 100px;
    max-width: 100%;
  }

  a i {
    color: #4f4f50;
    font-size: 18px !important;
    margin: 0 10px;
    transition: ease-out .18s;
    -webkit-transition: ease-out .18s;
    -moz-transition: ease-out .18s;
    -ms-transition: ease-out .18s;
    -o-transition: ease-out .18s;

    &:hover {
      color: $blue;
      transition: ease-out .18s;
      -webkit-transition: ease-out .18s;
      -moz-transition: ease-out .18s;
      -ms-transition: ease-out .18s;
      -o-transition: ease-out .18s;
    }
  }

  p {
    color: #4f4f50;
    margin: 0 9px 10px;
    transition: ease-out .18s;
    -webkit-transition: ease-out .18s;
    -moz-transition: ease-out .18s;
    -ms-transition: ease-out .18s;
    -o-transition: ease-out .18s;

    &:hover {
      color: $blue;
      transition: ease-out .18s;
      -webkit-transition: ease-out .18s;
      -moz-transition: ease-out .18s;
      -ms-transition: ease-out .18s;
      -o-transition: ease-out .18s;
    }
  }
}

.copyright {
  padding: 15px 0;
  background: #ddddde;

  p {
    font-weight: 400;
    font-size: 13px;
    color: #6a6c6c;
    margin: 0;

    @include breakpoint(xs) {
      text-align: center !important;
    }

    b {
      font-weight: bold;
    }
  }

  .copy {
    @include respond-to(768) {
      position: relative;
      top: 3px;
    }
  }

  .text-right {
    .content {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 70px;
        margin-left: 10px;
      }

      p {
        position: relative;
        top: -1px;
      }

      @include breakpoint(xs) {
        text-align: center !important;

        img {
          margin-top: 10px;
        }

        p {
          top: 3px;
        }
      }
    }
  }
}


.ytp-cued-thumbnail-overlay-image {
  transform: scale(1.1) !important;
}