.center-text-xs{
  @include breakpoint(xs){
    text-align: center;
  }
}
#pinturerias-mobile{
  .twitter-typeahead{
    display: block!important;
  }
}
.wrap-buscador{
  h1{
    font-size: 25px;
    color: #515050;
    font-weight: 300;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    letter-spacing: 3px;
    width: 80%;
    margin: 30px 0;
    display: inline-block;
    @include breakpoint(xxs){
      letter-spacing: 1px;
      font-size: 20px;
      margin-bottom: 20px;
      text-align: center;
      width: 100%;
    }
  }
  .back-color{
    margin-top: 27px;
    @include breakpoint(xxs){
      width: 100%;
      text-align: center;
      float: none;
      display: block;
      margin: 0px 0 25px;
    }
  }
  .content-buscador{
    border: 1px solid #efefef;
    margin: 0 0 60px 0;
    .header-buscador{
      border-bottom: 1px solid #efefef;
      background: white;
      padding: 35px 25px;
      @include breakpoint(xs){
        padding: 25px;
      }
      @include breakpoint(xxs){
        padding: 25px 0;
      }
      .check-tintometrico{
        text-align: right;
        display: block!important;
        @include breakpoint(xs){
          display: none;
        }
        .form-group{
          &:before{
            display: none;
          }
          .custom-check{
            background: transparent;
            border-radius: 4px;
            position: relative;
            width: 15px;
            height: 15px;
            border: 1px solid;
            display: inline-block;
            &:before{
              content: "\f3fd";
              font-family: 'Ionicons';
              color: white;
              font-size: 0;
              position: absolute;
              top: 50%;
              left: 50%;
              @include center(-50%, -50%);
            }
            input{
              position: absolute;
              opacity: 0;
              left: 0;
              margin: 0;
            }
          }
          .custom-check-checked{
            background: $blue;
            &:before{
              font-size: 16px;
            }
          }
          label{}
          i{
            font-size: 20px;
            padding: 0 10px;
            cursor: pointer;
            color: $blue;
          }
        }
        .popover{
          width: 230px!important;
        }
        @include breakpoint(xs){
          text-align: center;
          padding: 25px 0 0 0;
        }
      }
      form{
        display: inline-block;
        .form-group{
          margin: 0;
          padding: 0;
          display: inline-block;
          position: relative;
          @include breakpoint(xs){
            display: block;
          }
          // .input-group{
            // position: relative;
            // @include breakpoint(xs){
            //   margin: auto;
            // }
            label{
              // color: #ccc;
              // position: absolute;
              // top: 10px;
              // font-size: 14px;
              // color: #ccc;
              // left: 0;
              // transition: ease-out .18s;
              // -webkit-transition: ease-out .18s;
              // -moz-transition: ease-out .18s;
              // -ms-transition: ease-out .18s;
              // -o-transition: ease-out .18s;
            }
            .form-control{
              @include breakpoint(md){
                width: 140px;
              }
            }
            .input-next{
              padding: 10px 15px;
              i{
                font-size: 20px;
                color: #545454;
              }
            }
            .usar-ubicacion{
              text-transform: uppercase;
              display: block;
              font-size: 12px;
              position: relative;
              margin-left: 30px;
              @include breakpoint(xs){
                margin-left: 0;
              }
              &:before{
                content: "\f2e9";
                font-family: 'Ionicons';
                position: absolute;
                top: 50%;
                left: -25px;
                @include centerY(-50%);
                color: $blue;
                font-size: 22px;
              }
            }
          // }
          input[type="checkbox"]:focus{
            outline: none;
          }
          label{
            font-family: 'Roboto', sans-serif;
            font-size: 12px;
            color: #ccc;
            @include breakpoint(xs){
              left: 0;
            }
          }
        }
        .my-loc{
          // height: 30px;
          @include breakpoint(xs){
            margin-left: 20px;
          }
          .input-group{
            a{
              @include breakpoint(sm){
                font-size: 10px;
              }
            }
          }
        }
        .form-group:first-child{
          // margin-left: 30px;
          // .input-group{
            &:before{
              content: "\f4a4";
              font-family: 'Ionicons';
              position: absolute;
              top: 50%;
              left: -25px;
              @include centerY(-50%);
              color: #b2b9bd;
              font-size: 22px;
            }
          // }
        }
        .form-group:nth-of-type(2){
          margin-left: 30px;
          @include breakpoint(xs){
            // margin-right: 30px;
          }
          // .input-group{
            &:before{
              content: "\f3a3";
              font-family: 'Ionicons';
              position: absolute;
              top: 50%;
              left: -25px;
              @include centerY(-50%);
              color: #b2b9bd;
              font-size: 22px;
            }
          // }
        }

        // .form-group.is-focused{
        //   .input-group{
        //     border-color: $blue;
        //     label{
        //       font-size: 12px;
        //       top: -10px;
        //       left: 0;
        //       color: $blue;
        //       transition: ease-out .18s;
        //       -webkit-transition: ease-out .18s;
        //       -moz-transition: ease-out .18s;
        //       -ms-transition: ease-out .18s;
        //       -o-transition: ease-out .18s;
        //     }
        //   }
        // }
      }
    }
    .center-content-buscador{
      .sidebar-buscador{
        padding: 0;
        height: 650px;
        overflow-y: scroll;
        position: relative;
        @include breakpoint(sm){
          max-height: 500px;
          height: auto;
          display: flex;
          flex-wrap: wrap;
          padding-bottom: 10px;
        }
        @include breakpoint(xs){
          max-height: 300px;
        }
        .item-buscador{
          padding: 10px;
          background: white;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border-bottom: 1px solid #efefef;
          cursor: pointer;
          @include breakpoint(sm){
            width: 50%;
            border-right: 1px solid #efefef;
            max-height: 125px;
          }
          @include breakpoint(xs){
            width: 100%;
          }
          .distance{
            margin: 0 20px 0 0;
            text-align: center;
            width: 50px;
            @include breakpoint(md){
              margin: 0 15px 0 0;
            }
            @include breakpoint(sm){
            }
            i{
              color: #b2b9bd;
              font-size: 40px;
              text-align: center;
            }
            h2{
              font-size: 11px;
              color: #333333;
              text-transform: uppercase;
              text-align: center;
              font-family: 'Roboto', sans-serif;
              font-weight: 300;
              margin: 0;
              display: block;
              width: 50px;
            }
          }
          .separator{
            width: 2px;
            background: #ebebeb;
            position: relative;
            height: 100px;
            top: 50%;
            right: 0;
            margin: 0 0 0 5px;
            @include breakpoint(md){
              margin: 0 10px 0 0;
            }
            @include breakpoint(sm){
              @include centerY(-50%);
            }
          }
          .description{
            margin: 0 30px;
            @include breakpoint(md){
              margin: 0;
            }
            h1{
              font-family: 'Roboto', sans-serif;
              font-size: 16px;
              color: $blue;
              font-weight: 600;
              letter-spacing: 2px;
              margin: 0 0 15px 0;
              @include breakpoint(xs){
                margin: 0;
              }
              @include breakpoint(xxs){
                font-size: 14px;
              }
            }
            p{
              font-size: 11px;
              color: #b2b9bd;
              font-family: 'Roboto', sans-serif;
              font-weight: 300;
              letter-spacing: 1px;
              margin: 0;
              line-height: 21px;
              @include breakpoint(xxs){
                line-height: 16px;
              }
            }
            p:nth-of-type(3){
              @include breakpoint(xs){
                display: none;
              }
            }
          }
          &:hover{
            // background: #f8f8f9;
            background: $blue;
            .distance{
              i{
                color: white;
              }
              h2{
                color: white;
              }
            }
            .description{
              h1{
                color: white;
              }
              p{
                color: white;
              }
            }
          }
        }
        .active{
          // background: #f8f8f9;
          background: $blue;
          .distance{
            i{
              color: white;
            }
            h2{
              color: white;
            }
          }
          .description{
            h1{
              color: white;
            }
            p{
              color: white;
            }
          }
        }
        .item-buscador:last-child{
          border-bottom: none;
        }
      }
      ::-webkit-scrollbar{
        width: 10px;
      }
      ::-webkit-scrollbar-button{
        display: none;
      }
      ::-webkit-scrollbar-track{
        height: 100%;
        background-color: #f8f8f9;
        border: 1px solid #efefef;
      }
      ::-webkit-scrollbar-track-piece  { /* 4 */ }
      ::-webkit-scrollbar-thumb        {
        background-color: #b2b9bd;
      }
      ::-webkit-scrollbar-corner       { /* 6 */ }
      ::-webkit-resizer                { /* 7 */ }
      .map-container{
        padding: 0;
        #buscador-map{
          height: 650px;
          @include breakpoint(sm){
            height: 500px;
          }
          @include breakpoint(xs){
            height: 300px;
          }
        }
      }
    }
  }
}


/*
.twitter-typeahead .tt-query,
.twitter-typeahead .tt-hint {
  margin-bottom: 0;

height: 30px;
}
.tt-hint {
   .form-control;
}*/

.form-control[disabled]{
  border-bottom: 0!important;
}

.typeahead, .tt-query, .tt-hint {
/*width: 200px !important;*/

margin-bottom: 0;
}


.tt-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
}


.tt-suggestion {
  display: block;
  padding: 3px 20px;
}

.tt-suggestion.tt-is-under-cursor {
  color: #fff;
  background-color: #3a74c3;
  background-image: -moz-linear-gradient(top, #0088cc, #0077b3);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0077b3));
  background-image: -webkit-linear-gradient(top, #0088cc, #0077b3);
  background-image: -o-linear-gradient(top, #0088cc, #0077b3);
  background-image: linear-gradient(to bottom, #0088cc, #0077b3);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc', endColorstr='#ff0077b3', GradientType=0)
}

.tt-suggestion.tt-is-under-cursor a {
  color: #fff;
}

.tt-suggestion p {
  margin: 0;
}
.twitter-typeahead .tt-suggestion.tt-cursor {
    text-decoration: none;
   color: #fff;
    background-color: #3a74c3;
    cursor: pointer;

}


.pac-container {
    z-index: 10000 !important;
}

section.search-home {
  background-color: #f3f3f3;
  padding: 40px 0;

  .container {
    margin-top: 102px;
    background-color: white;
    padding: 20px;

    .btn-group {
      @include breakpoint(sm){
        width: 100%;
      }
    }

    .search-wrapper {

      .title {
        p {
          font-size: 23px;
          margin-top: 13px;
          font-weight: bold;
          color: #3a74c3;
          margin-bottom: 0;
        }
        @media (max-width: 992px){
          text-align: center;
        }
      }

      .btn-wrapper {
        margin-top: 8px;
        @media (max-width: 992px){
          margin-top: 20px;
        }
      }

      .custom-padding {
        @include breakpoint(sm){
          text-align: center;

          .this_text {
            font-size: 14px;
          }
        }
      }

      .btn-group {

        .btn {
          background: transparent;
          border-bottom: 2px solid #3a74c3;
          border-radius: 0;
          padding: 15px 3px 15px 10px;
          text-align: left;
          width: 180px;
          font-size: 15px;
          font-weight: 600;
          color: #b2b9bd;

          @include breakpoint(xxs){
            font-size: 12px;
          }

          @include breakpoint(xs){
            width: 100%;
            margin-bottom: 10px;
          }

          .fa {
            float: right;
            color: red;
            font-size: 20px;
            font-weight: 700;
            margin-left: 10px;
          }
        }
      }
      .btn-group.open .dropdown-toggle, .btn.active, .btn:active {
        background: #FFF;
        border-bottom-color: #FFF;
        box-shadow: 3px 3px 12px rgba(0,0,0,.175);
      }
      .dropdown-menu {
        border: none;
        border-radius: 0;
        font-size: 12px;
        margin: 0;
        min-width: 180px;
        width: 180px;
        @include breakpoint(sm){
          width: 100%!important;
          min-width: inherit!important;
        }

        > li > a {
          color: #7a7a7a;
          padding: 10px;
        }

        > li > a:focus, > li > a:hover {
          background: #eaeaea;
          color: #1958ae;
        }
      }

      a.bt {
        background: #1958ae;
        border-radius: 2px;
        color: #FFF;
        display: inline-block;
        float: right;
        font-size: 14px;
        line-height: 40px;
        padding: 0 70px;
        text-transform: uppercase;
      }
    }
  }
}

