// Path Icons Bootstrap
$icon-font-path: '../fonts/';
//Path Icons Font-Awesome
// $fa-font-path: '../bower_components/font-awesome/fonts';
// $fa-font-path: "./fonts/";
// Variables custom bootstrap
@import 'variables';
// Bootstrap
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap";
// Font Awesome
@import 'bower_components/font-awesome/scss/font-awesome';
// Font Ionicons
@import 'bower_components/ionicons/scss/ionicons';
// Fancybox
@import "components/fancybox";
// Google Fonts
@import 'https://fonts.googleapis.com/css?family=Montserrat:400,700';
@import 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,700,800';
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700');

@import 'animate';
@import 'owl-carousel';

// Mixins
@import 'mixins';
// Material Forms
@import 'material-kit';
// My Style
@import 'globals';
@import 'color';
@import 'buscador';
@import 'asistente-tecnico';
@import 'compartir';
// Media Queries
@import 'medias';
